import { injectable } from "inversify";
import { FaceFitNoteDetailsViewModel } from "./model/FaceFitNoteDetailsViewModel";
import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { GetConsultingNote } from "@/domain/usecase/consulting/model/GetConsultingNote";
import type { GetConsultationRecording } from "@/domain/usecase/consulting/model/GetConsultationRecording";
import { FaceFit } from "@/application/view-data";
import { BehaviorSubject, Subject, from, map, Observable } from "rxjs";
import type { UpdateConsultingStatus } from "@/domain/usecase/consulting/model/UpdateConsultingStatus";
import type { UpdateReservationStatus } from "@/domain/usecase/consulting/model/UpdateReservationStatus";
import type { UpdateConsultingMemo } from "@/domain/usecase/consulting/model/UpdateConsultingMemo";
import type { UpdateConsultingReservationDate } from "@/domain/usecase/consulting/model/UpdateConsultingReservationDate";
import type { GetSignedURL } from "@/domain/usecase/gcs/model/GetSignedURL";
import Utils from "@/application/view-model/util/ViewModelUtil";
import { ObjectStorage } from "@/storage/ncloud/ObjectStorage";

@injectable()
export class FaceFitNoteDetailsViewModelImpl implements FaceFitNoteDetailsViewModel {
  private objectStorage = new ObjectStorage("caremind-v1");

  data: FaceFitNoteDetailsViewModel["data"] = {
    note: null,
  };

  output: FaceFitNoteDetailsViewModel["output"] = {
    note: new BehaviorSubject<FaceFit.Note | null>(null),
    latestTranscriptionData: new BehaviorSubject<{
      recording: FaceFit.RecordingFileInfo;
      segments: FaceFit.ConsultingTranscriptionSegment[];
    } | null>(null),
    memoModal: new Subject<{ isOpen: boolean; memo: string | null }>(),
    reservationDateModal: new Subject<{ isOpen: boolean; reservationDate: Date | null }>(),
  };

  constructor(
    readonly noteId: number,
    readonly uiSystem: UISystemManager,
    private readonly ucGetConsultingNote: GetConsultingNote,
    private readonly ucUpdateConsultingStatus: UpdateConsultingStatus,
    private readonly ucUpdateReservationStatus: UpdateReservationStatus,
    private readonly ucUpdateConsultingMemo: UpdateConsultingMemo,
    private readonly ucUpdateConsultingReservationDate: UpdateConsultingReservationDate,
    private readonly ucGetSignedURL: GetSignedURL,
  ) {
    this.init(noteId);
  }

  input: FaceFitNoteDetailsViewModel["input"] = {
    clickConsultingStatus: (consultingStatus) => {
      const sub = this.ucUpdateConsultingStatus.execute({ noteId: this.noteId, consultingStatus }).subscribe({
        next: ({ operationConsultingNoteId }) => {
          if (this.data.note) {
            this.data.note.consultingStatus = consultingStatus;
            this.output.note.next({ ...this.data.note });
          }
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },
    clickReservationStatus: (reservationStatus) => {
      const sub = this.ucUpdateReservationStatus.execute({ noteId: this.noteId, reservationStatus }).subscribe({
        next: ({ operationConsultingNoteId }) => {
          if (this.data.note) {
            this.data.note.reservationStatus = reservationStatus;
            this.output.note.next({ ...this.data.note });

            if (reservationStatus === "RESERVED") {
              this.output.reservationDateModal.next({ isOpen: true, reservationDate: null });
            }
          }
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },
    clickOpenReservationDate: (isOpen, reservationDate) => {
      this.output.reservationDateModal.next({ isOpen, reservationDate });
    },
    clickUpdateReservationDate: (date) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const sub = this.ucUpdateConsultingReservationDate
        .execute({ noteId: this.noteId, reservationDate: new Date(date).toISOString() })
        .subscribe({
          next: () => {
            this.init(this.noteId);
            this.output.reservationDateModal.next({ isOpen: false, reservationDate: null });
            this.uiSystem.loadingHandler.backdropLoading.next(false);
            sub.unsubscribe();
          },
          error: () => {
            sub.unsubscribe();
          },
        });
    },

    clickOpenMemo: (isOpen, memo) => {
      this.output.memoModal.next({ isOpen, memo });
    },

    clickUpdateMemo: (memo) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const sub = this.ucUpdateConsultingMemo.execute({ noteId: this.noteId, memo }).subscribe({
        next: () => {
          if (this.data.note) {
            this.data.note.memo = memo;
            this.output.note.next({ ...this.data.note });
          }
          this.output.memoModal.next({ isOpen: false, memo: null });
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
      });
    },

    clickDownloadMP3: () => {
      if (this.data.note) {
        const latestTranscriptionSegments = this.getLatestTranscriptionSegments(this.data.note.records);
        if (latestTranscriptionSegments) {
          this.objectStorage
            .getObject(latestTranscriptionSegments.recording.naverObjectStorageUrl)
            .then((blob) => {
              if (this.data.note) {
                const fileName = `id${this.data.note.id}_${Utils.formatUCT(latestTranscriptionSegments.createdAt, "yyyy.mm.dd")}.mp3`;
                this.downloadFile(blob, fileName);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          // const sub = this.ucGetSignedURL
          //   .execute({
          //     privateURL: latestTranscriptionSegments.recording.googleCloudStorageUrl,
          //     expiresIn: 5000,
          //   })
          //   .subscribe({
          //     next: ({ signedUrl }) => {
          //       if (this.data.note) {
          //         const fileName = `id${this.data.note.id}_${Utils.formatUCT(latestTranscriptionSegments.createdAt, "yyyy.mm.dd")}`;
          //         this.downloadFile(signedUrl, fileName);
          //       }
          //       sub.unsubscribe();
          //     },
          //     error: () => {
          //       sub.unsubscribe();
          //     },
          //   });
        }
      }
    },
    clickDownloadTxT: () => {
      if (this.data.note?.records) {
        const latestTranscriptionSegments = this.getLatestTranscriptionSegments(this.data.note.records);
        if (latestTranscriptionSegments) {
          const fileName = `id${this.data.note.id}_${Utils.formatUCT(latestTranscriptionSegments.createdAt, "yyyy.mm.dd")}.txt`;
          const text = this.getTranscriptionText(fileName, this.data.note.records);
          this.downloadTxtFile(text, fileName);
        }
      }
    },
  };

  private init = (noteId: number) => {
    const sub = this.ucGetConsultingNote.execute({ noteId }).subscribe({
      next: (note) => {
        const latestTranscriptionSegments = this.getLatestTranscriptionSegments(note.records);
        this.data.note = note;
        this.output.note.next(note);
        this.output.latestTranscriptionData.next(latestTranscriptionSegments);
        sub.unsubscribe();
      },
      error: () => {
        sub.unsubscribe();
      },
    });
  };

  private getLatestTranscriptionSegments = (
    records: FaceFit.ConsultingRecording[],
  ): { createdAt: string; recording: FaceFit.RecordingFileInfo; segments: FaceFit.ConsultingTranscriptionSegment[] } | null => {
    if (!records || records.length === 0) return null;

    const validRecords = records.filter((record) => record.transcription.result.segments.length > 0);

    if (validRecords.length === 0) return null;

    const latestRecord = validRecords.reduce((latest, current) => {
      return new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest;
    });

    return {
      createdAt: latestRecord.createdAt,
      recording: latestRecord.recording,
      segments: latestRecord.transcription.result.segments,
    };
  };

  private downloadFile = async (blob: Blob, filename: string) => {
    try {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {}
  };

  private downloadTxtFile = (text: string, filename: string) => {
    const blob = new Blob([text], { type: "text/plain" });
    const blobUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(blobUrl);
  };

  private getTranscriptionText = (title: string, records: FaceFit.ConsultingRecording[]): string => {
    if (!records || records.length === 0) return "";

    let resultText = "";

    records.forEach((record) => {
      const createdAtUTC = new Date(record.createdAt);
      const createdAtKST = new Date(createdAtUTC.getTime() + 9 * 60 * 60 * 1000);

      const createdAt = createdAtKST.toLocaleString("ko-KR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      });

      const totalDuration = record.transcription.result.segments[record.transcription.result.segments.length - 1]?.end || 0;
      const formattedDuration = Utils.formatMsToTime(totalDuration);

      resultText += `${title}\n`;
      resultText += `${createdAt} ・ ${formattedDuration}\n`;
      resultText += `${record.recording.filename.split(".")[0]}\n\n`;

      record.transcription.result.segments.forEach((segment, index) => {
        const formattedTime = Utils.formatMsToTime(segment.start);
        resultText += `참석자 ${segment.speaker.label} ${formattedTime}\n`;
        resultText += `${segment.text}\n\n`;
      });
    });

    return resultText.trim();
  };
}
