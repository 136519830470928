import * as Entity from "@/domain/entity";
import { OperationCategoryGroup } from "./FaceFit";

export const MEDICAL_SUBJECTS: { id: number; defaultName: string; code: Entity.Type.SubjectCode }[] = [
  {
    id: 7,
    defaultName: "피부",
    code: "SKINCARE",
  },
  {
    id: 18,
    defaultName: "눈성형",
    code: "EYES",
  },
  {
    id: 2,
    defaultName: "코성형",
    code: "NOSE",
  },
  {
    id: 3,
    defaultName: "윤곽",
    code: "FACIAL_CONTOURING",
  },
  {
    id: 4,
    defaultName: "양악",
    code: "ORTHOGNATHIC",
  },
  {
    id: 11,
    defaultName: "지방흡입",
    code: "LIPO_SUCTION",
  },
  {
    id: 10,
    defaultName: "지방이식",
    code: "MICRO_LIPO_INJECTION",
  },
  {
    id: 9,
    defaultName: "가슴성형",
    code: "BREAST",
  },
  {
    id: 8,
    defaultName: "필러/보톡스",
    code: "FILLER",
  },
  {
    id: 6,
    defaultName: "리프팅",
    code: "LIFTING",
  },
  {
    id: 15,
    defaultName: "안티에이징",
    code: "ANTI_AGING",
  },
  {
    id: 16,
    defaultName: "한방",
    code: "ORIENTAL",
  },
  {
    id: 13,
    defaultName: "모발",
    code: "HAIR_TRANSPLANT",
  },
  {
    id: 12,
    defaultName: "치아",
    code: "TOOTH",
  },
  {
    id: 14,
    defaultName: "제모",
    code: "WAXING",
  },
  {
    id: 17,
    defaultName: "기타",
    code: "ETC",
  },
];

export const SUBWAYS: Entity.Type.Subway[] = [
  "GANGNAM",
  "APGUJEONG",
  "SINSA",
  "SINNONHYUN",
  "NONHYUN",
  "ETC_SEOUL",
  "INCHEON_TERMINAL",
  "GYEONGBUK_UNIVERSITY_HOSPITAL",
  "YEOKSAM",
  "SUNREUNG",
  "INCHEON_CITY_HALL",
  "PYEONGCHON",
  "INCHEON_UNIVERSITY_ENTRANCE",
  "GEUMJEONG",
  "APGUJEONG_RODEO",
  "BEOMGYE",
  "BONGEUNSA",
  "EONJU",
  "SEOMYEON",
  "GANGNAMGU_OFFICE",
  "MYEONGHAK",
  "CHEONGDAM",
  "JAMSILSAENAE",
  "ANYANG",
  "SEOUL_NATIONAL_UNIVERSITY_OF_EDUCATION",
  "BANWOLDANG",
  "SPORT_COMPLEX",
  "SAMSUNG",
  "SAMSUNG_JUNGANG",
  "ARTS_CENTER",
  "HAKDONG",
];

export const USER_ROLES: Entity.Type.UserRole[] = ["ADMIN", "DOCTOR", "NURSE", "CONSULTANT", "MARKETER", "ETC"];

export const SPECIALTIES: string[] = [
  "전문의 아님",
  "성형외과",
  "피부과",
  "마취통증의학과",
  "이비인후과",
  "내과",
  "외과",
  "가정의학과",
  "영상의학과",
  "산부인과",
  "안과",
  "응급의학과",
  "비뇨의학과",
  "정형외과",
  "소아청소년과",
  "흉부외과",
];

export const OPERATION_PARTS: { code: "EYE" | "NOSE" | "FACIAL_CONTOURING"; name: "눈" | "코" | "윤곽" }[] = [
  { code: "EYE", name: "눈" },
  { code: "NOSE", name: "코" },
  { code: "FACIAL_CONTOURING", name: "윤곽" },
];

export const OPERATION_CATEGORY_GROUPS: OperationCategoryGroup[] = [
  {
    id: 1,
    name: "매몰",
    ordinal: 1,
    subjectCode: "EYES",
    categories: [
      {
        id: 8,
        subjectCode: "EYES",
        operationName: "매몰",
        ordinal: 1,
      },
      {
        id: 13,
        subjectCode: "EYES",
        operationName: "매몰(재)",
        ordinal: 2,
      },
    ],
  },
  {
    id: 2,
    name: "절개",
    ordinal: 2,
    subjectCode: "EYES",
    categories: [
      {
        id: 14,
        subjectCode: "EYES",
        operationName: "절개",
        ordinal: 3,
      },
      {
        id: 26,
        subjectCode: "EYES",
        operationName: "절개(재)",
        ordinal: 4,
      },
    ],
  },
  {
    id: 3,
    name: "눈매교정",
    ordinal: 3,
    subjectCode: "EYES",
    categories: [
      {
        id: 11,
        subjectCode: "EYES",
        operationName: "눈매교정",
        ordinal: 5,
      },
      {
        id: 25,
        subjectCode: "EYES",
        operationName: "눈매교정(재)",
        ordinal: 6,
      },
    ],
  },
  {
    id: 4,
    name: "트임",
    ordinal: 4,
    subjectCode: "EYES",
    categories: [
      {
        id: 9,
        subjectCode: "EYES",
        operationName: "뒤트임",
        ordinal: 9,
      },
      {
        id: 10,
        subjectCode: "EYES",
        operationName: "앞트임",
        ordinal: 7,
      },
      {
        id: 17,
        subjectCode: "EYES",
        operationName: "앞트임(재)",
        ordinal: 8,
      },
      {
        id: 34,
        subjectCode: "EYES",
        operationName: "밑트임",
        ordinal: 11,
      },
      {
        id: 36,
        subjectCode: "EYES",
        operationName: "뒤트임(재)",
        ordinal: 10,
      },
      {
        id: 43,
        subjectCode: "EYES",
        operationName: "밑트임(재)",
        ordinal: 12,
      },
    ],
  },
  {
    id: 5,
    name: "상.하안검",
    ordinal: 5,
    subjectCode: "EYES",
    categories: [
      {
        id: 19,
        subjectCode: "EYES",
        operationName: "상안검",
        ordinal: 13,
      },
      {
        id: 23,
        subjectCode: "EYES",
        operationName: "하안검",
        ordinal: 15,
      },
      {
        id: 24,
        subjectCode: "EYES",
        operationName: "안검내반증수술",
        ordinal: 26,
      },
      {
        id: 38,
        subjectCode: "EYES",
        operationName: "상안검(재)",
        ordinal: 14,
      },
      {
        id: 39,
        subjectCode: "EYES",
        operationName: "하안검(재)",
        ordinal: 16,
      },
    ],
  },
  {
    id: 6,
    name: "눈밑",
    ordinal: 6,
    subjectCode: "EYES",
    categories: [
      {
        id: 16,
        subjectCode: "EYES",
        operationName: "눈밑지방재배치",
        ordinal: 18,
      },
      {
        id: 18,
        subjectCode: "EYES",
        operationName: "눈썹밑거상",
        ordinal: 21,
      },
      {
        id: 22,
        subjectCode: "EYES",
        operationName: "눈밑지방제거",
        ordinal: 17,
      },
    ],
  },
  {
    id: 7,
    name: "거상",
    ordinal: 7,
    subjectCode: "EYES",
    categories: [
      {
        id: 28,
        subjectCode: "EYES",
        operationName: "눈썹거상",
        ordinal: 20,
      },
      {
        id: 30,
        subjectCode: "EYES",
        operationName: "이마거상",
        ordinal: 22,
      },
    ],
  },
  {
    id: 8,
    name: "지방이식",
    ordinal: 8,
    subjectCode: "EYES",
    categories: [
      {
        id: 15,
        subjectCode: "EYES",
        operationName: "눈위지방이식",
        ordinal: 25,
      },
      {
        id: 21,
        subjectCode: "EYES",
        operationName: "눈꺼풀지방이식",
        ordinal: 23,
      },
      {
        id: 37,
        subjectCode: "EYES",
        operationName: "눈지방이식",
        ordinal: 23,
      },
      {
        id: 40,
        subjectCode: "EYES",
        operationName: "눈밑지방이식",
        ordinal: 19,
      },
    ],
  },
];
