import * as Entity from "@/domain/entity";
import { AnalysisStatus } from "@/domain/entity/consulting/consulting.entity";
import { injectable } from "inversify";
import { Observable } from "rxjs";
import { API, ApiCancelToken, CancelTokenSource } from "../../API";
import { OperationConsultingAPI } from "./model/OperationConsultingAPI";
import {
  CreateConsultingNoteRequest,
  CreateConsultingNoteV2Request,
  GetBestPracticePhotosRequest,
  GetBestPracticeReqeust,
  GetConsultationRecordingReqeust,
  PatchBestPracticeExposureStatusReqeust,
  PatchConsultingMemo,
  PatchConsultingReservationDate,
  PatchConsultingStatus,
  PatchReservationStatus,
  PostConsultationRecordingRequest,
  PostConsultingTranscriptionsRequest,
  PostFaceSimilarityRequest,
  PostOperationConsultingLog,
  PostPersonaShiftRequest,
  PutConsultingNoteRequest,
  PutConsultingNoteV2Request,
} from "./model/Request";
import {
  ConsultantResponse,
  GetAIAnalysisResponse,
  GetBestPracticePhotosResponse,
  GetBestPracticesResponse,
  GetConsultationRecordingResponse,
  GetConsultingNoteResponse,
  GetConsultingNotesV2Response,
  GetConsultingNoteV2Response,
  GetFaceStyles,
  GetInfluencerPhotos,
  GetOperationCategoryGroups,
  GetTags,
  PostConsultingTranscriptionsResponse,
  PostFaceSimilarityResponse,
} from "./model/Response";
import { GetOperationCategories } from "../hospital/model/Response";

@injectable()
export class OperationConsultingAPIImpl implements OperationConsultingAPI {
  private getBestPracticeCancelTokenSource: CancelTokenSource | null = null;

  createConsultingNote(request: CreateConsultingNoteRequest): Observable<{ operationConsultingNoteId: number }> {
    return API.post({ credential: true, path: "/dr-wo/v1/operation-consulting-notes", data: request });
  }

  putConsultingNote(noteId: number, request: PutConsultingNoteRequest): Observable<{ operationConsultingNoteId: number }> {
    return API.put({ credential: true, path: `/dr-wo/v1/operation-consulting-note/${noteId}`, data: request });
  }

  postFaceLandmark(noteId: number, photoId: number): Observable<{ analysisId: number; status: AnalysisStatus }> {
    return API.post({
      credential: true,
      path: `/dr-wo/v1/operation-consulting-note/${noteId}/face-landmark`,
      data: { operationConsultingNotePhotoId: photoId },
    });
  }

  postPersonaShift(noteId: number, request: PostPersonaShiftRequest): Observable<{ analysisId: number; status: AnalysisStatus }> {
    return API.post({ credential: true, path: `/dr-wo/v1/operation-consulting-note/${noteId}/face-shift`, data: request });
  }

  getBestPractice(reqeust: GetBestPracticeReqeust): Observable<GetBestPracticesResponse> {
    if (this.getBestPracticeCancelTokenSource) {
      this.getBestPracticeCancelTokenSource.cancel();
      this.getBestPracticeCancelTokenSource = ApiCancelToken.source();
    } else {
      this.getBestPracticeCancelTokenSource = ApiCancelToken.source();
    }
    return API.get({
      credential: true,
      path: "/dr-wo/v1/best-practices",
      queryParams: {
        page: reqeust.page,
        size: reqeust.size,
        gender: reqeust.gender,
        categories: reqeust.categories,
        exposure: reqeust.exposure,
      },
      cancelToken: this.getBestPracticeCancelTokenSource.token,
    });
  }

  getAIAnalysis(noteId: number): Observable<GetAIAnalysisResponse> {
    return API.get({ credential: true, path: `/dr-wo/v1/operation-consulting-note/${noteId}/analysis` });
  }

  postFaceSimilarity(request: PostFaceSimilarityRequest): Observable<PostFaceSimilarityResponse> {
    return API.post({
      credential: true,
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/face-similarity`,
      data: {
        operationConsultingNotePhotoId: request.operationConsultingNotePhotoId,
        calculatePhotoOriginUrl: request.calculatePhotoOriginUrl,
        calculateSimilarityRequestType: request.similarityRequestType,
        photos: request.photos,
      },
    });
  }

  getInfluencerPhotos(): Observable<GetInfluencerPhotos> {
    return API.get({ credential: true, path: "/dr-wo/v1/influencers" });
  }

  patchBestPracticeExposureStatus(reqeust: PatchBestPracticeExposureStatusReqeust): Observable<void> {
    return API.patch({
      path: "/dr-wo/v1/best-practices/photos/exposure",
      credential: true,
      data: { exposureOn: reqeust.exposureOn, exposureOff: reqeust.exposureOff },
    });
  }

  getConsultingNoteV2(noteId: number): Observable<GetConsultingNoteV2Response> {
    return API.get({ credential: true, path: `/dr-wo/v1/operation-consulting-note/${noteId}` });
  }

  getConsultingNotesV2(
    page: number,
    size: number,
    consultingStatus?: Entity.Type.ConsultStatusForFaceFit,
    reservationStatus?: Entity.Type.OperationStatusForFaceFit,
    keyword?: string,
    consultant?: number,
  ): Observable<GetConsultingNotesV2Response> {
    return API.get({
      credential: true,
      path: "/dr-wo/v1/operation-consulting-notes",
      queryParams: { page, size, keyword, consulting_status: consultingStatus, reservation_status: reservationStatus, consultant },
    });
  }

  createConsultingNoteV2(request: CreateConsultingNoteV2Request): Observable<{ operationConsultingNoteId: number }> {
    return API.post({ credential: true, path: "/dr-wo/v1/operation-consulting-notes", data: request });
  }

  putConsultingNoteV2(noteId: number, request: PutConsultingNoteV2Request): Observable<{ operationConsultingNoteId: number }> {
    return API.put({ credential: true, path: `/dr-wo/v1/operation-consulting-note/${noteId}`, data: request });
  }
  getConsultants(): Observable<ConsultantResponse> {
    return API.get({ credential: true, path: "/dr-wo/v1/hospital/consultants" });
  }

  getBestPracticePhotos(request: GetBestPracticePhotosRequest): Observable<GetBestPracticePhotosResponse> {
    return API.get({ path: "/dr-wo/v1/best-practices/photos", credential: true, queryParams: { id: request.ids } });
  }

  patchConsultingStatus(request: PatchConsultingStatus): Observable<{ operationConsultingNoteId: number }> {
    return API.patch({
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/consulting-status`,
      credential: true,
      data: { consultingStatus: request.consultingStatus },
    });
  }

  patchReservationStatus(request: PatchReservationStatus): Observable<{ operationConsultingNoteId: number }> {
    return API.patch({
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/reservation-status`,
      credential: true,
      data: { reservationStatus: request.reservationStatus },
    });
  }

  patchConsultingMemo(request: PatchConsultingMemo): Observable<{ operationConsultingNoteId: number }> {
    return API.patch({
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/memo`,
      credential: true,
      data: { memo: request.memo },
    });
  }

  patchConsultingReservationDate(request: PatchConsultingReservationDate): Observable<{ operationConsultingNoteId: number }> {
    return API.patch({
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/reservation-date`,
      credential: true,
      data: { reservationDate: request.reservationDate },
    });
  }

  getTags = (): Observable<GetTags> => {
    return API.get({ path: "/dr-wo/v1/tags", credential: true });
  };

  getFaceStyles = (): Observable<GetFaceStyles> => {
    return API.get({ path: "/dr-wo/v1/face-styles", credential: true });
  };

  getOperationCategoryGroups = (): Observable<GetOperationCategoryGroups> => {
    return API.get({ path: "/dr-wo/v1/operation-categories/groups", credential: true });
  };

  postConsultationRecording = (request: PostConsultationRecordingRequest): Observable<{ recordId: number }> => {
    return API.post({
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/record`,
      credential: true,
      data: {
        recordingOriginUrl: request.recordingOriginUrl,
        recordingFilename: request.recordingFilename,
        recordingDurationTime: request.recordingDurationTime,
      },
    });
  };

  getConsultationRecording = (reqeust: GetConsultationRecordingReqeust): Observable<GetConsultationRecordingResponse> => {
    return API.get({
      path: `/dr-wo/v1/operation-consulting-note-record/${reqeust.recordId}`,
      credential: true,
    });
  };

  postConsultingTranscriptions = (request: PostConsultingTranscriptionsRequest): Observable<PostConsultingTranscriptionsResponse> => {
    return API.post({ path: `/dr-wo/v1/operation-consulting-note-record/${request.recordId}/transcription`, credential: true });
  };

  postOperationConsultingLog = (request: PostOperationConsultingLog): Observable<{ logId: number }> => {
    return API.post({
      path: `/dr-wo/v1/operation-consulting-note/${request.noteId}/logs`,
      credential: true,
      data: { productCode: request.productCode, logCode: request.logCode },
    });
  };
}
