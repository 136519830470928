import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { injectable } from "inversify";
import { FaceSimilarityFittingViewModel } from "./model/FaceSimilarityFittingViewModel";
import { BehaviorSubject, interval, mergeMap, Subject, zip } from "rxjs";
import { FaceFit, Consulting, Hospital, UI, Constant } from "@/application/view-data";
import type { GetBestPractices } from "@/domain/usecase/consulting/model/GetBestPractices";
import type { GetDoctors } from "@/domain/usecase/doctor/model/GetDoctors";
import type { RequestFaceSimilarity } from "@/domain/usecase/consulting/model/RequestFaceSimilarity";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import type { GetOperationCategories, GetOperationCategoriesOutput } from "@/domain/usecase/hospital/model/GetOperationCategories";
import type { GetTags } from "@/domain/usecase/consulting/model/GetTags";
import type { GetOperationCategoryGroups } from "@/domain/usecase/consulting/model/GetOperationCategoryGroups";
import * as Entity from "@/domain/entity";

@injectable()
export class FaceSimilarityFittingViewModelImpl implements FaceSimilarityFittingViewModel {
  data: FaceSimilarityFittingViewModel["data"] = {
    doctors: null,
    allbeforeAndAfterReivews: [],
    allTags: [],
    beforeAndAfterReviews: null,
    operationCategories: [],
    faceSimilarities: [],
    filters: FaceFit.EYES_FILTERS,
    genderFilters: FaceFit.GENDER_FILTERS,
    tagFilters: [],
    operationCategoryGroups: [],
    operationCategoryFilterItems: [],
    selectedSubject: null,
  };
  output: FaceSimilarityFittingViewModel["output"] = {
    isInitialSetupSuccessful: new Subject<boolean>(),
    faceFitScoreAnalysisStatus: new Subject<"PENDING" | "IN_PROGRESS" | "COMPLETED">(),
    isMosaic: new Subject<boolean>(),
    doctors: new Subject<Consulting.Doctor[]>(),
    selectedDoctor: new Subject<Consulting.Doctor>(),
    beforeAndAfterReviews: new BehaviorSubject<Consulting.BeforeAndAfterReview[] | null>(null),
    selectedBeforeAndAfterReview: new Subject<Consulting.BeforeAndAfterReview>(),
    openDoctorBriefHistory: new Subject<{ open: boolean; doctor: Consulting.Doctor | null }>(),
    filters: new Subject<FaceFit.Filter[]>(),
    genderFilters: new Subject<{ gender: string; isSelected: boolean }[]>(),
    loading: new Subject<boolean>(),
    tagFilters: new Subject<UI.SelectableItem<FaceFit.Tag>[]>(),
    operationCategoryFilterItems: new Subject<UI.SelectableItem<FaceFit.OperationCategoryGroup>[]>(),
    selectedSubject: new Subject<Entity.Type.SubjectCode>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly getBestPractices: GetBestPractices,
    private readonly getDoctors: GetDoctors,
    private readonly reqeustFaceSimilarity: RequestFaceSimilarity,
    private readonly getAIAnalysis: GetAIAnalysis,
    private readonly getOperationCategories: GetOperationCategories,
    private readonly getTags: GetTags,
    private readonly getOperationCategoryGroups: GetOperationCategoryGroups,
  ) {
    this.uiSystem = uiSystem;
    this.init();
  }

  input: FaceSimilarityFittingViewModel["input"] = {
    clickDoctor: (doctor: Consulting.Doctor) => {
      this.output.selectedDoctor.next(doctor);
    },
    clickDoctorBriefHistory: (doctor) => {
      this.output.openDoctorBriefHistory.next({ open: true, doctor });
    },
    clickBeforeAndAfterReview: (review: Consulting.BeforeAndAfterReview) => {
      this.output.selectedBeforeAndAfterReview.next(review);
    },
    clickFaceSimilarityFitting: (note) => {
      const noteId = note.id;
      const photo = note.photos && note.photos[0];

      if (noteId && photo?.id && photo.originUrl) {
        this.output.faceFitScoreAnalysisStatus.next("IN_PROGRESS");
        this.data.filters = FaceFit.EYES_FILTERS;

        this.output.beforeAndAfterReviews.next(null);
        this.output.filters.next(this.data.filters);
        this.output.genderFilters.next(this.data.genderFilters);

        const sub = this.reqeustFaceSimilarity
          .execute({
            noteId,
            photoId: photo.id,
            photoURL: this.removeSignedURL(photo.originUrl),
            similarityRequestType: "BEST_PRACTICE_BEFORE",
            photos: [],
          })
          .subscribe({
            next: (faceSimilarity) => {
              this.getFaceSimilarityByBeforePhoto(noteId, faceSimilarity.analysisId);
              sub.unsubscribe();
            },
            error: () => {
              sub.unsubscribe();
            },
          });
      }
    },
    clickFilter: (selectedFilter: FaceFit.Filter) => {
      if (selectedFilter.category === "전체") {
        this.data.filters = this.data.filters.map((filter) => {
          if (this.data.filters.some(({ category, isSelected }) => category === "전체" && isSelected)) {
            return {
              ...filter,
              isSelected: false,
            };
          }
          return {
            ...filter,
            isSelected: true,
          };
        });
      } else {
        this.data.filters = this.data.filters.map((filter) => {
          if (filter.category === "전체") {
            return { ...filter, isSelected: false };
          } else if (filter.category === selectedFilter.category) {
            return { ...filter, isSelected: !filter.isSelected };
          } else {
            return filter;
          }
        });
      }

      this.output.filters.next(this.data.filters);
    },
    clickBestPracticePhoto: (bestPracticePhoto) => {
      this.output.selectedBeforeAndAfterReview.next(bestPracticePhoto);
    },
    clickGenderFilter: (gender) => {
      this.data.genderFilters = this.data.genderFilters.map((filter) => {
        if (filter.gender === gender) {
          filter.isSelected = !filter.isSelected;
          return filter;
        } else {
          return filter;
        }
      });

      this.output.genderFilters.next(this.data.genderFilters);
      this.getFilteredBestPractices();
    },
    clickTagFilter: (selectedTag) => {
      this.data.tagFilters = this.data.tagFilters.map((tag) => {
        if (tag.data.id === selectedTag.id) {
          tag.isSelected = !tag.isSelected;
          return tag;
        } else {
          return tag;
        }
      });
      this.output.tagFilters.next(this.data.tagFilters);
      this.getFilteredBestPractices();
    },
    clickOperationCategoryFilterItem: (item: FaceFit.OperationCategoryGroup) => {
      this.data.operationCategoryFilterItems = this.data.operationCategoryFilterItems.map((filterItem) => {
        if (item.id === filterItem.data.id) {
          return {
            ...filterItem,
            isSelected: !filterItem.isSelected,
          };
        } else {
          return filterItem;
        }
      });
      this.output.operationCategoryFilterItems.next(this.data.operationCategoryFilterItems);
      this.getFilteredBestPractices();
    },
    clickSubjectFilterItem: (subject) => {
      this.data.selectedSubject = subject;
      this.data.operationCategoryFilterItems = this.getOperationCategoryFilterItemsBySubject(subject);
      this.data.tagFilters = this.getTagFilterItemsBySubject(subject);

      this.output.selectedSubject.next(subject);
      this.output.operationCategoryFilterItems.next(this.data.operationCategoryFilterItems);
      this.output.tagFilters.next(this.data.tagFilters);

      this.getFilteredBestPractices();
    },
  };

  event: FaceSimilarityFittingViewModel["event"] = {
    onGetFaceFitHitory: (noteId) => {
      const sub = this.getAIAnalysis.execute({ noteId }).subscribe({
        next: ({ faceSimilarities }) => {
          if (faceSimilarities.length > 0) {
            const latestFaceFit = faceSimilarities.reduce((max, item) => {
              return item.id > max.id && item.status === "SUCCESS" ? item : max;
            }, faceSimilarities[0]);

            if (latestFaceFit.status === "SUCCESS") {
              this.setFaceSimilarity(latestFaceFit);
              this.output.faceFitScoreAnalysisStatus.next("COMPLETED");
            } else {
              this.output.faceFitScoreAnalysisStatus.next("PENDING");
            }
          } else {
            this.output.faceFitScoreAnalysisStatus.next("PENDING");
          }
          sub.unsubscribe();
        },
        error: () => {
          this.output.faceFitScoreAnalysisStatus.next("PENDING");
          sub.unsubscribe();
        },
      });
    },
    onFiltersChanged: (filters: FaceFit.Filter[]) => {
      this.filterBestPracticePhotos();
    },
    onSetStartSubject: (startSubject) => {
      this.data.selectedSubject = startSubject;
      this.data.operationCategoryFilterItems = this.getOperationCategoryFilterItemsBySubject(startSubject);
      this.data.tagFilters = this.getTagFilterItemsBySubject(startSubject);

      this.output.operationCategoryFilterItems.next(this.data.operationCategoryFilterItems);
      this.output.tagFilters.next(this.data.tagFilters);
      this.output.selectedSubject.next(startSubject);
      this.getFilteredBestPractices();
    },
  };

  private init = () => {
    this.output.genderFilters.next(this.data.genderFilters);
    const sub = this.getDoctors
      .execute()
      .pipe(
        mergeMap((doctors) => {
          this.data.doctors = doctors.items
            .filter((d) => d.faceFitConfiguration?.exposed === true)
            .map((doctor) => {
              return {
                id: doctor.id,
                name: doctor.profile.name,
                rank: doctor.profile.rank,
                profile: doctor.faceFitConfiguration?.profileImageUrl ?? "",
                specialty: doctor.profile.specialty ?? "",
                briefHistory: doctor.profile?.briefHistory ? doctor.profile.briefHistory : "",
                ordinal: doctor.faceFitConfiguration?.exposureOrdinal ?? 0,
              };
            });

          this.data.doctors.sort((a, b) => a.ordinal - b.ordinal);
          this.output.doctors.next(this.data.doctors);
          this.output.selectedDoctor.next({ ...this.data.doctors[0] });

          return this.getBestPractices.execute({ page: 1, size: 400, gender: null, categories: [], exposure: true });
        }),
        mergeMap((bestPractices) => {
          this.data.allbeforeAndAfterReivews = bestPractices.items.map((item) => {
            const beforePhoto = item.photos.find((photo) => photo.type === "BEFORE" && photo.ordinal === 1);
            const beforePhotoId = item.photos.find((photo) => photo.type === "BEFORE" && photo.kind === "FACE_FRONTAL")?.id;
            const afterPhoto = item.photos.find((photo) => photo.type === "AFTER" && photo.ordinal === 1);

            return {
              id: item.id,
              before: {
                id: beforePhotoId ? beforePhotoId : beforePhoto?.id ? beforePhoto.id : 0,
                photo: beforePhoto?.originUrl ?? "",
                kind: beforePhoto?.kind ?? null,
                photos: item.photos.filter((photo) => photo.type === "BEFORE"),
              },
              after: {
                id: afterPhoto?.id ?? 0,
                photo: afterPhoto?.originUrl ?? "",
                kind: afterPhoto?.kind ?? null,
                photos: item.photos.filter((photo) => photo.type === "AFTER"),
                label: "",
              },
              operationCategories: item.categories.sort((a, b) => a.ordinal - b.ordinal),
              gender: item.gender,
              elapsedTime: item.elapsedTime,
              tags: item.tags,
            };
          });

          this.data.beforeAndAfterReviews = [...this.data.allbeforeAndAfterReivews];

          if (this.data.faceSimilarities.length > 0) {
            this.data.beforeAndAfterReviews = this.data.beforeAndAfterReviews
              ? this.data.beforeAndAfterReviews?.map((review) => {
                  const similarity = this.data.faceSimilarities?.find((photo) => photo.id === review.before.id);
                  if (similarity) {
                    return { ...review, ratio: this.adjustSimilarityRatio(similarity.similarity) };
                  } else {
                    return { ...review, ratio: 20 };
                  }
                })
              : null;
            if (this.data.beforeAndAfterReviews) {
              this.data.beforeAndAfterReviews.sort((a, b) => {
                return (b.ratio ?? 0) - (a.ratio ?? 0);
              });
              this.data.allbeforeAndAfterReivews = this.data.beforeAndAfterReviews;
            }
          }

          return zip(this.getOperationCategories.execute(), this.getTags.execute(), this.getOperationCategoryGroups.execute());
        }),
      )
      .subscribe({
        next: ([operationCategories, { tags }, operationCategoryGroups]) => {
          this.data.operationCategories = this.mapToOperationCategory(operationCategories);
          this.data.allTags = tags;
          this.data.tagFilters = tags.map((tag) => {
            return { data: tag, isSelected: false };
          });

          if (operationCategoryGroups.items.length > 0) {
            this.data.operationCategoryGroups = operationCategoryGroups.items;
          } else {
            this.data.operationCategoryGroups = Constant.OPERATION_CATEGORY_GROUPS;
          }

          this.output.filters.next(this.data.filters);
          this.output.tagFilters.next(this.data.tagFilters);
          this.output.isInitialSetupSuccessful.next(true);

          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
  };

  private getFaceSimilarityByBeforePhoto = (noteId: number, analysisId: number) => {
    if (noteId) {
      this.output.loading.next(true);
      const sub = interval(2000)
        .pipe(
          mergeMap(() => {
            return this.getAIAnalysis.execute({ noteId: noteId });
          }),
        )
        .subscribe({
          next: ({ faceSimilarities }) => {
            const results = faceSimilarities.find((faceSimilarity) => faceSimilarity.id === analysisId);

            if (results?.result) {
              if (results.result.photos?.length > 0) {
                this.data.faceSimilarities = results?.result?.photos;
                this.data.allbeforeAndAfterReivews = this.data.allbeforeAndAfterReivews
                  ? this.data.allbeforeAndAfterReivews.map((review) => {
                      const similarity = results?.result?.photos?.find((photo) => photo.id === review.before.id);
                      if (similarity) {
                        return { ...review, ratio: this.adjustSimilarityRatio(similarity.similarity) };
                      } else {
                        return { ...review, ratio: 20 };
                      }
                    })
                  : [];
                if (this.data.allbeforeAndAfterReivews) {
                  this.data.allbeforeAndAfterReivews.sort((a, b) => {
                    return (b.ratio ?? 0) - (a.ratio ?? 0);
                  });

                  this.output.beforeAndAfterReviews.next([...this.data.allbeforeAndAfterReivews]);
                }
              }

              if (results.status === "SUCCESS") {
                this.getFilteredBestPractices();
                this.output.loading.next(false);
                this.output.faceFitScoreAnalysisStatus.next("COMPLETED");
                sub.unsubscribe();
              }
            } else if (results?.status === "FAIL") {
              this.output.loading.next(false);
              this.output.faceFitScoreAnalysisStatus.next("PENDING");
              this.uiSystem.errorHandler.alert.next({ message: `Face Fitting을 실패하였습니다.` });
              sub.unsubscribe();
            }
          },
          error: () => {
            this.output.loading.next(false);
            sub.unsubscribe();
          },
        });
    }
  };

  private removeSignedURL = (signedURL: string) => {
    const [url, _] = signedURL.split("?");
    return url;
  };

  private mapToOperationCategory = (getOperationCategoriesOutput: GetOperationCategoriesOutput) => {
    return getOperationCategoriesOutput.items.map((operationCategory) => {
      const category: FaceFit.HospitalOperationCategory = {
        ...operationCategory,
        filters: ["전체"],
      };
      if (operationCategory.operationName.startsWith("눈밑")) {
        category.filters.push("눈밑");
      }
      if (operationCategory.operationName.includes("(재)")) {
        category.filters.push("재수술");
      }
      if (operationCategory.operationName.includes("트임")) {
        category.filters.push("트임");
      }
      if (operationCategory.operationName.includes("눈매교정")) {
        category.filters.push("눈매교정");
      }
      if (operationCategory.operationName.includes("절개")) {
        category.filters.push("절개");
      }
      if (operationCategory.operationName.includes("매몰")) {
        category.filters.push("매몰");
      }
      if (operationCategory.operationName.includes("거상")) {
        category.filters.push("거상");
      }
      if (operationCategory.operationName.includes("상안검") || operationCategory.operationName.includes("하안검")) {
        category.filters.push("상.하안검");
      }
      if (!operationCategory.operationName.includes("눈밑") && operationCategory.operationName.includes("지방이식")) {
        category.filters.push("지방이식");
      }
      return category;
    });
  };

  private filterBestPracticePhotos = () => {
    if (this.data.beforeAndAfterReviews) {
      this.data.beforeAndAfterReviews = this.data.allbeforeAndAfterReivews?.filter((item) => {
        const selectedGenders = this.data.genderFilters.filter((filter) => filter.isSelected).map((g) => g.gender);
        const result = selectedGenders.includes(item.gender === "FEMALE" ? "여성" : "남성");
        return result;
      });

      if (this.data.filters.every(({ isSelected }) => !isSelected)) {
        this.data.beforeAndAfterReviews = [];
        this.data.beforeAndAfterReviews = this.filterBestPracticeByTags(this.data.beforeAndAfterReviews);
        return this.output.beforeAndAfterReviews.next([...this.data.beforeAndAfterReviews]);
      } else {
        const filteredCategoryIds = this.getOperationCategoryIdsByFilters(this.data.filters);

        if (filteredCategoryIds.length === 0) {
          return this.output.beforeAndAfterReviews.next([...this.data.beforeAndAfterReviews]);
        }

        if (this.data.allbeforeAndAfterReivews.length > 0) {
          this.data.beforeAndAfterReviews = this.data.beforeAndAfterReviews.filter((item) => {
            return item.operationCategories.some((category) => {
              return filteredCategoryIds.some((filteredCategoryId) => category.operationCategoryId === filteredCategoryId);
            });
          });
        }
      }

      this.data.beforeAndAfterReviews = this.filterBestPracticeByTags(this.data.beforeAndAfterReviews);

      this.output.beforeAndAfterReviews.next([...this.data.beforeAndAfterReviews]);
    }
  };

  private filterBestPracticeByTags = (bestPractices: Consulting.BeforeAndAfterReview[]) => {
    const selectedTagIds = this.data.tagFilters.filter((tag) => tag.isSelected).map((selectedTag) => selectedTag.data.id);

    if (selectedTagIds.length > 0) {
      const filteredResult = bestPractices.filter((bestPractice) => {
        return bestPractice.tags.some((tag) => selectedTagIds.includes(tag.tagId));
      });

      return filteredResult;
    } else {
      return bestPractices;
    }
  };

  private getOperationCategoryIdsByFilters = (filters: FaceFit.Filter[]) => {
    const categoryIds = filters
      .filter(({ isSelected }) => isSelected)
      .map((selectedFilter) => {
        return this.data.operationCategories
          .filter((operationCategory) => {
            return operationCategory.filters.some((filter) => {
              return filter === selectedFilter.category;
            });
          })
          .map((operationCategory) => operationCategory.id);
      })
      .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
      .sort((a, b) => a - b);

    return Array.from(new Set(categoryIds));
  };

  private getOperationCategoryFilterItemsBySubject = (subject: Entity.Type.SubjectCode) => {
    const operationCategoryGroups = this.data.operationCategoryGroups.filter((group) => {
      return group.subjectCode === subject;
    });

    const result: UI.SelectableItem<FaceFit.OperationCategoryGroup>[] = operationCategoryGroups.map((group) => {
      return {
        data: group,
        isSelected: true,
      };
    });

    return result;
  };

  private getTagFilterItemsBySubject = (subject: Entity.Type.SubjectCode): UI.SelectableItem<FaceFit.Tag>[] => {
    const tags = this.data.allTags.filter((tag) => tag.subjectCode === subject || tag.subjectCode === null);
    return tags.map((tag) => {
      return {
        isSelected: false,
        data: tag,
      };
    });
  };

  private getFilteredBestPractices = () => {
    let bestPractices = this.data.allbeforeAndAfterReivews;
    bestPractices = this.getBestPracticesForFilteredOperationCategories(bestPractices);
    bestPractices = this.getBestPracticesForFilteredGender(bestPractices);
    bestPractices = this.getBestPracticesForFilteredTag(bestPractices);
    this.output.beforeAndAfterReviews.next([...bestPractices]);
  };

  //성별 필터 결과 가져오기
  private getBestPracticesForFilteredGender = (bestPractices: Consulting.BeforeAndAfterReview[]) => {
    const selectedGenderFilterItems = this.data.genderFilters
      .filter((gender) => gender.isSelected)
      .map((data) => (data.gender === "남성" ? "MALE" : "FEMALE"));

    bestPractices = bestPractices.filter((bestPractice) => {
      return selectedGenderFilterItems.includes(bestPractice.gender);
    });

    return bestPractices;
  };

  //수술종류별 필터 결과 가져오기
  private getBestPracticesForFilteredOperationCategories = (bestPractices: Consulting.BeforeAndAfterReview[]) => {
    const selectedOperationCategoryFilterItems = this.data.operationCategoryFilterItems
      .filter((item) => item.isSelected)
      .map((item) => item.data);

    bestPractices = bestPractices.filter((bestPractice) =>
      bestPractice.operationCategories.find((operationCategory) => operationCategory.subjectCode === this.data.selectedSubject),
    );

    bestPractices = bestPractices.filter((bestPractice) => {
      const result = bestPractice.operationCategories.find((operationCategory) => {
        return selectedOperationCategoryFilterItems.find((item) => {
          return item.categories.find((c) => c.operationName === operationCategory.operationName);
        });
      });

      return Boolean(result);
    });

    return bestPractices;
  };

  //태그 필터 결과 가져오기
  private getBestPracticesForFilteredTag = (bestPractices: Consulting.BeforeAndAfterReview[]) => {
    const selectedTagFilterItems = this.data.tagFilters.filter((tag) => tag.isSelected).map((tag) => tag.data);

    if (selectedTagFilterItems.length > 0) {
      bestPractices = bestPractices.filter((bestPractice) => {
        return selectedTagFilterItems.find((item) => {
          return bestPractice.tags.find((tag) => tag.name === item.name);
        });
      });
    }

    return bestPractices;
  };

  private adjustSimilarityRatio = (ratio: number) => {
    if (ratio > 89) {
      return ratio;
    } else if (ratio > 79) {
      return ratio + 10;
    } else if (ratio > 69) {
      return ratio + 10;
    } else if (ratio > 49) {
      return ratio + 20;
    } else if (ratio > 29) {
      return ratio + 20;
    } else if (ratio > 19 && ratio < 29) {
      return ratio + 20;
    } else {
      return ratio;
    }
  };

  private setFaceSimilarity = (faceSimilarity: Entity.Consulting.FaceSimilarity) => {
    if (faceSimilarity.result) {
      this.data.faceSimilarities = faceSimilarity?.result?.photos;
      this.data.faceSimilarities = faceSimilarity?.result?.photos;
      this.data.allbeforeAndAfterReivews = this.data.allbeforeAndAfterReivews
        ? this.data.allbeforeAndAfterReivews.map((review) => {
            const similarity = faceSimilarity?.result?.photos?.find((photo) => photo.id === review.before.id);
            if (similarity) {
              return { ...review, ratio: this.adjustSimilarityRatio(similarity.similarity) };
            } else {
              return { ...review, ratio: 20 };
            }
          })
        : [];
      if (this.data.allbeforeAndAfterReivews) {
        this.data.allbeforeAndAfterReivews.sort((a, b) => {
          return (b.ratio ?? 0) - (a.ratio ?? 0);
        });

        this.getFilteredBestPractices();
      }
    }
  };
}
