import { injectable } from "inversify";
import { RecentConsultationsViewModel } from "./model/RecentConsultationsViewModel";
import { Subject } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetConsultingNotes } from "@/domain/usecase/consulting/model/GetConsultingNotes";
import { FaceFit } from "@/application/view-data";
import * as Entity from "@/domain/entity";
import type { CreateOperationConsultingLog } from "@/domain/usecase/consulting/model/CreateOperationConsultingLog";
@injectable()
export class RecentConsultationsViewModelImpl implements RecentConsultationsViewModel {
  data: RecentConsultationsViewModel["data"] = {
    consultations: null,
  };

  output: RecentConsultationsViewModel["output"] = {
    consultations: new Subject<FaceFit.Consultation[] | null>(),
    openRecodingModal: new Subject<{ noteId: number | null }>(),
  };

  route: RecentConsultationsViewModel["route"] = {
    toOriginPath: new Subject<{ noteId: number }>(),
    toHome: new Subject<void>(),
    toConsultationStatusTab: new Subject<{ status: Entity.Type.ConsultStatusForFaceFit }>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly getConsultingNotes: GetConsultingNotes,
    private readonly ucCreateOperationConsultingLog: CreateOperationConsultingLog,
  ) {
    this.init();
  }

  input: RecentConsultationsViewModel["input"] = {
    clickLogo: () => {
      this.route.toHome.next();
    },
    clickRecording: (noteId) => {
      this.output.openRecodingModal.next({ noteId });
    },
    clickTab: (tab: Entity.Type.ConsultStatusForFaceFit) => {
      this.route.toConsultationStatusTab.next({ status: tab });
    },
  };

  event: RecentConsultationsViewModel["event"] = {
    onStartRecording: (noteId) => {
      this.route.toOriginPath.next({ noteId });
    },
    onCloseRecording: () => {
      this.output.openRecodingModal.next({ noteId: null });
    },
    onGetFilteredConsultations: (status, showLoading) => {
      this.init(status, showLoading);
    },
    onLogRecordingEnd: () => {
      const noteId = localStorage.getItem("end_recording_note_id");
      if (noteId) {
        const sub = this.ucCreateOperationConsultingLog
          .execute({
            noteId: Number(noteId),
            logCode: "RECORDING_END",
            productCode: "facefit.recording",
          })
          .subscribe({
            next: () => {
              this.init();
              localStorage.removeItem("end_recording_note_id");
              sub.unsubscribe();
            },
            error: () => {
              sub.unsubscribe();
            },
          });
      }
    },
  };

  private init = (status?: Entity.Type.ConsultStatusForFaceFit, showLoading?: boolean) => {
    if (showLoading) {
      this.output.consultations.next(null);
    }

    const sub = this.getConsultingNotes
      .execute({
        page: 1,
        size: 100,
        consultingStatus: null,
        reservationStatus: null,
        keyword: null,
      })
      .subscribe({
        next: (paginatedNotes) => {
          this.data.consultations = paginatedNotes.items;
          if (status) {
            switch (status) {
              case "PENDING":
                const pendingConsultations = this.filterRecordingPending(paginatedNotes.items);
                this.output.consultations.next(pendingConsultations);
                break;

              case "IN_PROGRESS":
                const inProgressResults = this.filterOngoingRecordings(paginatedNotes.items);
                this.output.consultations.next(inProgressResults);
                break;

              case "COMPLETED":
                const completedResults = this.filterCompletedRecording(paginatedNotes.items);
                this.output.consultations.next(completedResults);
                break;
            }
          } else {
            const pendingConsultations = this.filterRecordingPending(paginatedNotes.items);
            this.output.consultations.next(pendingConsultations);
          }

          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
  };

  private filterRecordingPending = (consultations: FaceFit.Consultation[]): FaceFit.Consultation[] => {
    return consultations.filter((consultation) => {
      const logs = consultation.logs.map((log) => log.code);

      if (consultation.recordings.length === 0) {
        const hasRecordingLogs = logs.some((log) => log === "RECORDING_START" || log === "RECORDING_END");
        if (!hasRecordingLogs) return true;

        let startCount = 0;
        for (const log of logs) {
          if (log === "RECORDING_START") startCount++;
          if (log === "RECORDING_END") startCount--;
        }

        return startCount === 0;
      } else {
        return false;
      }
    });
  };

  private filterOngoingRecordings = (consultations: FaceFit.Consultation[]): FaceFit.Consultation[] => {
    return consultations.filter((consultation) => {
      const logs = consultation.logs.map((log) => log.code);

      let startCount = 0;

      for (const log of logs) {
        if (log === "RECORDING_START") startCount++;
        if (log === "RECORDING_END") startCount--;
        if (startCount < 0) return true;
      }

      return startCount !== 0;
    });
  };

  private filterCompletedRecording = (consultations: FaceFit.Consultation[]): FaceFit.Consultation[] => {
    return consultations.filter((consultation) => {
      const logs = consultation.logs.map((log) => log.code);

      let startCount = 0;

      for (const log of logs) {
        if (log === "RECORDING_START") startCount++;
        if (log === "RECORDING_END") startCount--;

        if (startCount < 0) return false;
      }

      return consultation.recordings.length > 0;

      // return startCount === 0 && logs.includes("RECORDING_START") && logs.includes("RECORDING_END") && consultation.recordings.length > 0;
    });
  };
}
