import { AnalysisCode } from "@/domain/entity/ai-analysis/aiAnalysis.entity";
import { AnalysisStatus, ConsultingPhotoType, CustomerGender } from "@/domain/entity/consulting/consulting.entity";
import { SubjectCode } from "@/domain/entity/hospital/hospital.entity";
import { OperationCategory } from "./Hospital";
import { DoctorRank } from "@/domain/entity/doctor/doctor.entity";
import { ConsultingNote } from "@/domain/usecase/consulting/model/GetConsultingNotes";
import * as Entity from "@/domain/entity";

export interface Note {
  id?: number;
  hospitalId?: number;
  createdAt?: Date;
  afoterUserId?: number | null;
  afoterUserNickname?: string | null;
  customerName?: string | null;
  customerPhoneNumber?: string | null;
  customerGender?: CustomerGender | null;
  referral?: string | null;
  memo?: string | null;
  photos?: Photo[];
  surgeryParts?: string[];
  subjectCodes?: SubjectCode[];
  operationCategories?: OperationCategory[];
  reservationDate?: Date | null;
  consultantName: string | null;
}

export type CustomerNote = ConsultingNote;

export interface Photo {
  id?: number;
  type: ConsultingPhotoType;
  ordinal: number | null;
  originUrl: string | null;
  resizedUrl: string | null;
  objURL?: string;
  alignedImageURL?: string;
  faceLandmarkJSONURL?: string;
}

export interface Doctor {
  id: number;
  name: string;
  rank: DoctorRank;
  profile: string;
  specialty: string;
  briefHistory: string;
  ordinal: number;
}

export interface BeforeAndAfterReview {
  id: number;
  before: ReviewPhoto;
  after: ReviewPhoto;
  ratio?: number;
  operationCategories: OperationCategory[];
  isMatching?: boolean;
  gender: Entity.Type.GENDER;
  elapsedTime: string | null;
  tags: Tag[];
}

export interface Tag {
  id: number;
  tagId: number;
  name: string;
  ordinal: number;
  subjectCode: Entity.Type.SubjectCode | null;
}

export interface ReviewPhoto {
  id: number;
  photo: string;
  kind: Entity.Type.BestPracticePhotoKind | null;
  objURL?: string;
  label?: string;
  photos?: {
    id: number;
    ordinal: number;
    originUrl: string;
    type: "BEFORE" | "AFTER";
    kind: Entity.Type.BestPracticePhotoKind;
  }[];
}

export interface SurgeryPart {
  id: number;
  ordinal: number;
  operationName: string;
  subjectCode: SubjectCode;
}

export type NavigationType = "BEFORE_&_AFTER" | "BEFORE" | "AFTER" | "FACE_STYLE";

export interface AIAnalysis {
  id: number;
  code: AnalysisCode;
  status: AnalysisStatus;
  result: string;
}

export interface FaceLandmark {
  id: number;
  code: "FACE_LANDMARKS";
  result?: {
    alignedImageURL?: string;
    faceLandmarkJSONURL?: string;
  };
  status: AnalysisStatus;
}

export interface PersonaShiftOptions {
  beforePhoto: Photo;
  after: Photo;
}

export interface InfluencerPhoto {
  id: number;
  originURL: string;
  similarity?: number;
}

export type Status = "WAITING" | "PROGRESS" | "CANCEL" | "COMPLETED" | "ETC";
export type StatusForAd = "PENDING" | "MISSED" | "CANCELLED" | "ETC" | "COMPLETED";

export type Result = "RESERVATION" | "CANCEL" | "NONE" | "OPERATION_COMPLEDTED";

export type ResultForAd = "RESERVED" | "CANCELLED" | "NONE" | "COMPLETED";
export interface Consultant {
  id?: number;
  name: string;
}

export interface ConsultStatus {
  type: "consulting" | "reservation";
  statusCode: Entity.Type.ConsultStatusForFaceFit | Entity.Type.OperationStatusForFaceFit;
  statusForKor: string;
}
