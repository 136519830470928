import * as ViewData from "@/application/view-data";
import type { GetBestPracticePhotos } from "@/domain/usecase/consulting/model/GetBestPracticePhotos";
import { injectable } from "inversify";
import { Subject } from "rxjs";
import { FaceBestPracticesSliderViewModel } from "./model/FaceBestPracticesSliderViewModel";
@injectable()
export class FaceBestPracticesSliderViewModelImpl implements FaceBestPracticesSliderViewModel {
  data: FaceBestPracticesSliderViewModel["data"] = {
    bestPractices: null,
    selectedBestPractice: null,
  };
  output: FaceBestPracticesSliderViewModel["output"] = {
    bestPractices: new Subject<ViewData.FaceFit.BestPractice[]>(),
    selectedBestPractice: new Subject<ViewData.FaceFit.BestPractice>(),
  };

  constructor(bestPractices: ViewData.Consulting.BeforeAndAfterReview[], private readonly getBestPracticePhotos: GetBestPracticePhotos) {
    this.init(bestPractices);
  }

  event: FaceBestPracticesSliderViewModel["event"] = {
    onInitSlide: (selectedId, bestPractices) => {
      this.init(bestPractices);
      if (this.data.bestPractices) {
        const selectedBestPractice = this.data.bestPractices.find((bestPractice) => {
          return bestPractice.id === selectedId;
        });

        if (selectedBestPractice) {
          this.output.selectedBestPractice.next(selectedBestPractice);
          // this.getPhotoObjURL(selectedBestPractice);
        }
      }
    },
  };

  private init = (bestPractices: ViewData.Consulting.BeforeAndAfterReview[]) => {
    this.data.bestPractices = bestPractices.map((bestPractice) => {
      const beforeAndAfterItems: ViewData.FaceFit.BeforeAndAfter[] = [];

      bestPractice.before.photos?.forEach((photo) => {
        beforeAndAfterItems.push({
          before: {
            id: photo.id,
            ordinal: photo.ordinal,
            originURL: photo.originUrl,
            objURL: null,
            type: "BEFORE",
            kind: photo.kind,
          },
          after: null,
        });
      });

      bestPractice.after.photos?.forEach((photo) => {
        const index = beforeAndAfterItems.findIndex((item) => item.before?.ordinal === photo.ordinal);

        if (index > -1) {
          beforeAndAfterItems[index].after = {
            id: photo.id,
            ordinal: photo.ordinal,
            originURL: photo.originUrl,
            objURL: null,
            type: "AFTER",
            kind: photo.kind,
          };
        }
      });

      return {
        id: bestPractice.id,
        beforeAndAfterItems,
        label: bestPractice.after.label ?? null,
        operationCategories: bestPractice.operationCategories,
        ratio: bestPractice.ratio,
        elapsedTime: bestPractice.elapsedTime,
      };
    });

    this.output.bestPractices.next([...this.data.bestPractices]);
  };

  private getPhotoObjURL = (bestPractice: ViewData.FaceFit.BestPractice) => {
    const afterIds = bestPractice.beforeAndAfterItems.map((item) => item.after?.id).filter((id): id is number => id !== undefined);
    const beforeIds = bestPractice.beforeAndAfterItems.map((item) => item.before?.id).filter((id): id is number => id !== undefined);

    const ids = afterIds.concat(beforeIds);

    const sub = this.getBestPracticePhotos.execute({ ids }).subscribe({
      next: async ({ items }) => {
        if (this.data.bestPractices !== null) {
          const itemMap = new Map<number, { id: number; originUrl: string; type: string }>();

          items.forEach((item) => {
            itemMap.set(item.id, item);
          });

          const updateTasks: Promise<void>[] = [];

          this.data.bestPractices.forEach((bestPractice) => {
            bestPractice.beforeAndAfterItems.forEach((beforeAndAfterItem) => {
              const updateURL = (type: "before" | "after", id: number | undefined) => {
                if (id !== undefined && itemMap.has(id)) {
                  const item = itemMap.get(id)!;
                  beforeAndAfterItem[type]!.originURL = item.originUrl;

                  const updateTask = new Promise<void>((resolve) => {
                    this.createObjURL(item.originUrl, (objURL) => {
                      beforeAndAfterItem[type]!.objURL = objURL;
                      resolve();
                    });
                  });

                  updateTasks.push(updateTask);
                }
              };

              updateURL("before", beforeAndAfterItem.before?.id);
              updateURL("after", beforeAndAfterItem.after?.id);
            });
          });

          await Promise.all(updateTasks);

          this.data.bestPractices = this.data.bestPractices.map((bestPractice) => {
            bestPractice.beforeAndAfterItems.sort((a, b) => {
              if (a.after && b.after) {
                return a.after.ordinal - b.after.ordinal;
              } else {
                return 0;
              }
            });
            return bestPractice;
          });

          this.output.bestPractices.next([...this.data.bestPractices]);
        }

        sub.unsubscribe();
      },
      error: () => {
        sub.unsubscribe();
      },
    });
  };

  private convertURLtoFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      const ext = url.split(".").pop();
      const filename = url.split("/").pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], filename!, metadata);
    } catch (err) {
      throw err;
    }
  };

  private createObjURL = (originURL: string, completion: (objURL: string) => void) => {
    if (originURL) {
      this.convertURLtoFile(originURL).then((file) => {
        const objURL = window.URL.createObjectURL(file);
        completion(objURL);
      });
    }
  };
}
