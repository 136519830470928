import UISystemContainer from "../uiSystem.container";
import { ErrorHandler, LoadingHandler, PopupHandler, ScrollHandler, ToastHandler } from "../view-model/model";
import * as Error from "./error";
import * as Loading from "./load";
import * as Popup from "./popup";
import Toast from "./toast/Toast";
import { Subscription } from "rxjs";
import React, { useEffect } from "react";
import useMount from "@view/hooks/useMount";

const UISystem: React.FC = () => {
  const { popup, error, loading, toast } = useUISystem();

  return (
    <>
      {popup}
      {error}
      {toast}
      {loading}
    </>
  );
};

export default UISystem;

const useUISystem = () => {
  const container = UISystemContainer.instance;
  const UISystem = container.UISystem;
  const [popup, openPopup, closePopup] = useMount();
  const [error, openError, closeError] = useMount();
  const [loading, openLoading, closeLoading] = useMount();
  const [toast, openToast, closeToast] = useMount();

  const setPopupSubs = (popupHandler: PopupHandler): Subscription[] => {
    return [
      popupHandler.open.subscribe((popup) => {
        if (popup) {
          openPopup(popup);
        } else {
          closePopup();
        }
      }),
      popupHandler.alert.confirm.subscribe(({ open, infoMessage, confirm, close }) => {
        openPopup(
          <Popup.Alert.ConfirmAlert
            open={open}
            message={infoMessage}
            confirm={confirm}
            close={() => {
              closePopup();
              close && close();
            }}
          />,
        );
      }),
    ];
  };

  const setErrorSubs = (errorHandler: ErrorHandler): Subscription[] => {
    return [
      errorHandler.alert.subscribe(({ message }) => {
        openError(
          <Error.Alert
            open
            message={message}
            close={closeError}
          />,
        );
      }),
    ];
  };

  const setLoadingSubs = (loadingHandler: LoadingHandler): Subscription[] => {
    return [
      loadingHandler.backdropLoading.subscribe((isLoading) => {
        isLoading ? openLoading(<Loading.BackgroundLoading />) : closeLoading();
      }),
    ];
  };

  const scrollStateSubs = (scrollHandler: ScrollHandler): Subscription[] => {
    return [
      scrollHandler.output.restoring.subscribe((isRestoring) => {
        UISystem.loadingHandler.backdropLoading.next(isRestoring);
      }),
    ];
  };

  const toastSubs = (toastHandler: ToastHandler): Subscription[] => {
    return [
      toastHandler.toast.subscribe(({ message, position }) => {
        openToast(
          <Toast
            open
            message={message}
            position={position}
            close={closeToast}
          />,
        );
      }),
    ];
  };

  useEffect(() => {
    const subscriptions: Subscription[] = [
      ...setPopupSubs(UISystem.popupHandler),
      ...setErrorSubs(UISystem.errorHandler),
      ...setLoadingSubs(UISystem.loadingHandler),
      ...scrollStateSubs(UISystem.scrollHandler),
      ...toastSubs(UISystem.toastHandler),
    ];

    return () => {
      subscriptions.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  return { popup, error, loading, toast };
};
