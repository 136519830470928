import { injectable } from "inversify";
import { BestPracticeViewModel } from "@view-model/model/BestPracticeViewModel";
import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { GetBestPractices } from "@/domain/usecase/consulting/model/GetBestPractices";
import { Subject } from "rxjs";
import type { UpdateBestPracticeExposureStatus } from "@/domain/usecase/consulting/model/UpdateBestPracticeExposureStatus";
import { FaceFit } from "@view-data/index";

@injectable()
export class BestPracticeViewModelImpl implements BestPracticeViewModel {
  data: BestPracticeViewModel["data"] = {
    page: 1,
    size: 20,
    total: null,
    keyword: "",
    isNextPageLoading: false,
    photoInfos: [],
  };

  output: BestPracticeViewModel["output"] = {
    page: new Subject<number>(),
    size: new Subject<number>(),
    keyword: new Subject<string>(),
    total: new Subject<number | null>(),
    photoInfos: new Subject<FaceFit.BestPracticeConfig[]>(),
  };

  route: BestPracticeViewModel["route"] = {
    toQuery: new Subject<{ page: number; size: number; keyword: string }>(),
  };

  constructor(
    page: number,
    size: number,
    keyword: string,
    readonly uiSystem: UISystemManager,
    private readonly getBestPractices: GetBestPractices,
    private readonly updateBestPracticeExposureStatus: UpdateBestPracticeExposureStatus,
  ) {
    this.data.page = page;
    this.data.size = size;
    this.data.keyword = keyword;
    this.init();
  }

  input: BestPracticeViewModel["input"] = {
    clickPage: (page) => {
      this.data.page = page;
      this.init();
    },
    clickPhotoExposureOnOff: (exposure, bestId, ids) => {
      let exposureOff: number[] = [];
      let exposureOn: number[] = [];

      if (exposure === "ON") {
        exposureOn = exposureOn.concat(ids);
      } else {
        exposureOff = exposureOff.concat(ids);
      }

      this.data.photoInfos = this.data.photoInfos.map((best) => {
        if (best.id === bestId) {
          best.photos = best.photos.map((photo) => {
            photo.exposure = !photo.exposure;
            return photo;
          });
          return best;
        } else {
          return best;
        }
      });
      this.output.photoInfos.next(this.data.photoInfos);

      const sub = this.updateBestPracticeExposureStatus.execute({ exposureOff, exposureOn }).subscribe({
        next: () => {
          this.init();
          this.uiSystem.toastHandler.toast.next({
            message: "노출상태가 변경되었습니다.",
            position: { vertical: "top", horizontal: "center" },
          });
          sub.unsubscribe();
        },
        error: () => {
          this.init();
          this.uiSystem.errorHandler.alert.next({ message: "정상적으로 실행하지 못하였습니다.\n잠시후 다시 시도해주세요." });
          sub.unsubscribe();
        },
      });
    },
  };

  event: BestPracticeViewModel["event"] = {
    onUpdatePage: (page, size, keyword) => {
      this.data.page = page;
      this.data.size = size;
      this.data.keyword = keyword;
      this.init();
    },
  };

  private init() {
    const sub = this.getBestPractices
      .execute({ page: this.data.page, size: this.data.size, gender: null, categories: null, exposure: null })
      .subscribe({
        next: ({ items, total }) => {
          this.data.total = total;

          const result = items.map((item) => {
            return {
              id: item.id,
              categories: item.categories.map((c) => c.operationName),
              elapsedTime: item.elapsedTime,
              gender: item.gender,
              photos: item.photos.map((photo) => {
                return {
                  id: photo.id,
                  ordinal: photo.ordinal,
                  photo: photo.originUrl,
                  exposure: photo.exposure,
                  type: photo.type,
                };
              }),
              tags: item.tags,
            };
          });

          const sortedPhotos = this.sortPhotos(result);
          this.data.photoInfos = sortedPhotos;

          this.output.photoInfos.next(this.data.photoInfos);
          this.output.total.next(total);
          this.route.toQuery.next({ page: this.data.page, size: this.data.size, keyword: this.data.keyword });
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.errorHandler.alert.next({ message: "정상적으로 실행하지 못하였습니다.\n잠시후 다시 시도해주세요." });
          sub.unsubscribe();
        },
      });
  }

  private sortPhotos = (photoInfos: FaceFit.BestPracticeConfig[]): FaceFit.BestPracticeConfig[] => {
    photoInfos.sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    });

    photoInfos = photoInfos.map((photoInfo) => {
      photoInfo.photos = photoInfo.photos.sort((a, b) => {
        if (a.ordinal > b.ordinal) {
          return 1;
        } else if (a.ordinal < b.ordinal) {
          return -1;
        } else if (a.ordinal === b.ordinal) {
          if (a.type === "BEFORE") {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      });
      return photoInfo;
    });

    return photoInfos;
  };
}
