import { FaceFitViewModel, Query } from "@view-model/model/FaceFitViewModel";
import { injectable } from "inversify";
import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { BehaviorSubject, Subject, mergeMap, zip } from "rxjs";
import * as ViewData from "@/application/view-data";
import type { CreateConsultingNote } from "@/domain/usecase/consulting/model/CreateConsultingNote";
import type { UploadConsultingPhoto } from "@/domain/usecase/consulting/model/UploadConsultingPhoto";
import type { GetConsultingNote } from "@/domain/usecase/consulting/model/GetConsultingNote";
import type { UpdateConsultingNote } from "@/domain/usecase/consulting/model/UpdateConsultingNote";
import type { GetOperationCategories } from "@/domain/usecase/hospital/model/GetOperationCategories";
import { SubjectCode } from "@/domain/entity/hospital/hospital.entity";
import { CustomerGender } from "@/domain/entity/consulting/consulting.entity";
import type { GetContract } from "@/domain/usecase/hospital/model/GetContract";
import Utils from "./util/ViewModelUtil";
import * as Entity from "@/domain/entity";
import type { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import type { CreateConsultationRecording } from "@/domain/usecase/consulting/model/CreateConsultationRecording";
import type { CreateConsultingTranscriptions } from "@/domain/usecase/consulting/model/CreateConsultingTranscriptions";

@injectable()
export class FaceFitViewModelImpl implements FaceFitViewModel {
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private stream: MediaStream | null = null;

  data: FaceFitViewModel["data"] = {
    recordFile: null,
    hospitalId: null,
    editType: "CREATE",
    noteId: undefined,
    note: { customerName: "", customerPhoneNumber: "", photos: [], subjectCodes: [], consultantName: null },
    operationCategories: [],
    beforeAndAfterReviews: [],
    aiAnalysis: [],
    surgeryParts: [],
    fileName: "",
  };
  input: {
    clickUploadPhoto: (file: File, query: Query) => void;
    clickSaveConsultanting: () => void;
    clickStartConsulting: (query: Query, isMobile: boolean) => void;
    changeCustomerName: (name: string) => void;
    changeCustomerPhoneNumber: (phoneNumber: string) => void;
    changeMemo: (memo: string) => void;
    clickCustomerGender: (gender: CustomerGender) => void;
    clickSelectReferral: (referral: string) => void;
    clickOperationCategory: (subjectCode: SubjectCode) => void;
    clickDate: (date: string) => void;
    clickStartByFaceFitNoteId: (faceFitNoteId: number) => void;
    clickConfirmStartFaceFit: (faceFitNoteId: number) => void;
    clickRegisterCustomer: () => void;
    clickStartRecording: (noteId: number) => void;
  };
  output: FaceFitViewModel["output"] = {
    note: new Subject<ViewData.Consulting.Note>(),
    surgeryParts: new Subject<ViewData.Consulting.SurgeryPart[]>(),
    updateNoteSuccess: new Subject<boolean>(),
    operationCategories: new Subject<ViewData.Hospital.OperationCategory[]>(),
    photoByFaceLandmark: new Subject<{ src: string; normalizedFaceLandmark?: ViewData.FaceLandmark.Coordinate[][] }[]>(),
    alert: new Subject<{ open: boolean; message: string }>(),
    facelandmarks: new Subject<ViewData.Consulting.FaceLandmark[]>(),
    availableFaceFitPart: new BehaviorSubject<Entity.Type.SubjectCode[]>([]),
    startFaceScan: new Subject<boolean>(),
    openCustomerConfirmationModal: new Subject<{ open: boolean; faceFitNote: ViewData.Consulting.Note | null }>(),
    isCustomerRegistered: new Subject<boolean>(),
    faceFitLogoUrl: new Subject<string>(),
    hospitalId: new Subject<number>(),
    selectedSubjectCode: new Subject<SubjectCode[]>(),
  };
  route: FaceFitViewModel["route"] = {
    toBeforeAndAfter: new Subject<{ query: Query }>(),
    toBefore: new Subject<{ query: Query }>(),
    toAfter: new Subject<{ query: Query }>(),
    toFaceStyle: new Subject<{ query: Query }>(),
    toPhotoUploader: new Subject<{ query: Query }>(),
    toCloseModal: new Subject<{ query: Query }>(),
    toNoteId: new Subject<{ noteId: number; query: Query }>(),
    toCustomers: new Subject<void>(),
    toFaceFitByNoteId: new Subject<{ faceFitNoteId: number }>(),
    toBack: new Subject<void>(),
    toStandByMode: new Subject<void>(),
    toLatestConsultationForRecording: new Subject<{ noteId: number }>(),
  };

  constructor(
    readonly id: number | undefined,
    readonly uiSystem: UISystemManager,
    private readonly createConsultingNote: CreateConsultingNote,
    private readonly updateConsultingNote: UpdateConsultingNote,
    private readonly uploadConsultingPhoto: UploadConsultingPhoto,
    private readonly getConsultingNote: GetConsultingNote,
    private readonly getOperationCategories: GetOperationCategories,
    private readonly getContract: GetContract,
    private readonly getHospital: GetHospital,
    private readonly ucCreateConsultationRecording: CreateConsultationRecording,
    private readonly ucCreateConsultingTranscriptions: CreateConsultingTranscriptions,
  ) {
    this.uiSystem = uiSystem;
    this.init(id);
    this.input = {
      clickUploadPhoto: (file, query) => {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        this.data.fileName = file.name;

        const sub = this.uploadConsultingPhoto
          .execute({ file: file, ordinal: 1 })
          .pipe(
            mergeMap((result) => {
              this.data.note.photos = [
                { type: "FRONTAL", ordinal: 1, originUrl: result.originUrl, resizedUrl: result.resizedUrl, objURL: "" },
              ];

              if (this.data.note.id) {
                return this.updateConsultingNote.execute(this.data.note.id, { ...this.data.note }).pipe(
                  mergeMap(({ consultingNoteId }) => {
                    return this.getConsultingNote.execute({ noteId: consultingNoteId });
                  }),
                );
              } else {
                return this.createConsultingNote.execute(this.data.note).pipe(
                  mergeMap(({ consultingNoteId }) => {
                    return this.getConsultingNote.execute({ noteId: consultingNoteId });
                  }),
                );
              }
            }),
          )
          .subscribe({
            next: (note) => {
              this.data.note = note;

              const photo = note.photos ? note.photos[0] : null;

              if (note.photos && photo?.originUrl) {
                this.convertURLtoFile(photo.originUrl).then((file) => {
                  const objURL = window.URL.createObjectURL(file);
                  this.data.note.photos = [
                    {
                      id: photo.id,
                      type: "FRONTAL",
                      ordinal: 1,
                      originUrl: photo.originUrl,
                      resizedUrl: photo.resizedUrl,
                      objURL: objURL,
                    },
                  ];
                  this.output.note.next({ ...this.data.note });
                });
              } else {
                this.output.note.next({ ...this.data.note });
              }

              this.output.note.next({ ...this.data.note });

              if (!Boolean(this.data.noteId) && note.id) {
                this.data.noteId = note.id;
                this.route.toNoteId.next({ noteId: note.id, query });
              }

              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.output.startFaceScan.next(true);
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      },

      clickStartConsulting: (query, isMobile) => {
        this.updateNote(() => {
          if (isMobile) {
            this.output.alert.next({ open: true, message: "결과를 태블릿,PC에서 확인하세요." });
          } else {
            this.route.toCloseModal.next({ query });
          }
        });
      },
      clickSaveConsultanting: () => {
        const noteId = this.data.noteId;
        if (noteId) {
          this.uiSystem.loadingHandler.backdropLoading.next(true);
          const photos = this.data.note.photos?.map((photo) => {
            return {
              id: photo.id,
              type: photo.type,
              ordinal: photo.ordinal ?? 1,
              originUrl: photo.originUrl ? this.removeSignedURL(photo.originUrl) : "",
              resizedUrl: photo.resizedUrl ? this.removeSignedURL(photo.resizedUrl) : "",
            };
          });
          this.updateConsultingNote.execute(noteId, { ...this.data.note, photos }).subscribe({
            next: () => {
              this.output.updateNoteSuccess.next(true);
              this.uiSystem.loadingHandler.backdropLoading.next(false);
            },
            error: () => {
              this.output.updateNoteSuccess.next(true);
              this.uiSystem.loadingHandler.backdropLoading.next(false);
            },
          });
        }
      },

      changeCustomerName: (name) => {
        this.data.note.customerName = name;
        this.output.note.next({ ...this.data.note });
      },

      changeCustomerPhoneNumber: (phoneNumber) => {
        this.data.note.customerPhoneNumber = phoneNumber;
        this.output.note.next({ ...this.data.note });
      },

      changeMemo: (memo) => {
        this.data.note.memo = memo;
        this.output.note.next({ ...this.data.note });
      },

      clickSelectReferral: (referral) => {
        this.data.note.referral = referral;
        this.output.note.next({ ...this.data.note });
      },

      clickCustomerGender: (gender) => {
        if (this.data.note.customerGender === gender) {
          this.data.note.customerGender = undefined;
        } else {
          this.data.note.customerGender = gender;
        }
        this.output.note.next({ ...this.data.note });
      },
      clickOperationCategory: (subjectCode) => {
        if (this.data.note.subjectCodes?.some((code) => code === subjectCode)) {
          this.data.note.subjectCodes = this.data.note.subjectCodes.filter((code) => code !== subjectCode);
        } else {
          this.data.note.subjectCodes = [subjectCode];
        }

        this.output.note.next({ ...this.data.note });
        this.output.selectedSubjectCode.next(this.data.note.subjectCodes);
      },

      clickDate: (date) => {
        this.data.note.reservationDate = new Date(date);
        this.output.note.next({ ...this.data.note });
      },
      clickStartByFaceFitNoteId: (faceFitNoteId) => {
        if (faceFitNoteId && faceFitNoteId > 0) {
          const sub = this.getConsultingNote.execute({ noteId: faceFitNoteId }).subscribe({
            next: (faceFitNote) => {
              this.output.openCustomerConfirmationModal.next({ open: true, faceFitNote });
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.errorHandler.alert.next({ message: "고객정보를 찾지 못했습니다." });
              sub.unsubscribe();
            },
          });
        } else {
          this.uiSystem.errorHandler.alert.next({ message: "고객 아이디를 입력해주세요." });
        }
      },
      clickConfirmStartFaceFit: (faceFitNoteId) => {
        this.output.openCustomerConfirmationModal.next({ open: false, faceFitNote: null });
        this.route.toFaceFitByNoteId.next({ faceFitNoteId });
      },
      clickRegisterCustomer: () => {
        if (this.data.note.id) {
          this.updateNote(() => {
            this.output.isCustomerRegistered.next(true);

            setTimeout(() => {
              this.event.onStopRecord();
            }, 10000);
          });
        }
      },
      clickStartRecording: (noteId) => {
        this.route.toLatestConsultationForRecording.next({ noteId });
      },
    };
  }

  event: FaceFitViewModel["event"] = {
    onSaveConsulting: () => {
      this.updateNote();
    },
    onStartRecord: async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.stream = stream;
        this.audioChunks = [];
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.start();
      } catch (error) {
        console.error("음성녹음 권한이 거부되었습니다.", error);
      }
    },

    onStopRecord: async () => {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.mediaRecorder.onstop = async () => {
          if (this.data.noteId) {
            const recordingName = `${this.data.noteId}_${Utils.formatDate(new Date(), "yy-mm-dd")}`;
            const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
            const audioFile = new File([audioBlob], `${recordingName}.wav`, {
              type: "audio/wav",
            });
            this.data.recordFile = audioFile;

            const tracks = this.mediaRecorder?.stream.getTracks();
            tracks?.forEach((track) => track.stop());

            const ffmpeg = new FFmpeg();
            await ffmpeg.load();
            await ffmpeg.writeFile(`${recordingName}.wav`, await fetchFile(audioFile));
            await ffmpeg.exec(["-i", `${recordingName}.wav`, `${recordingName}.mp3`]);
            const mp3Data = await ffmpeg.readFile(`${recordingName}.mp3`);
            const mp3Blob = new Blob([mp3Data], { type: "audio/mp3" });
            const mp3File = new File([mp3Blob], `${recordingName}.mp3`, {
              type: "audio/mp3",
            });
            this.data.recordFile = mp3File;
            // this.CreateConsultationRecording(this.data.noteId, mp3File);
          }
        };
      }
    },
  };

  private init(id?: number) {
    if (id) {
      this.data.editType = "UPDATE";
      this.data.noteId = id;
      this.getConsultingNote.execute({ noteId: id }).subscribe({
        next: (note) => {
          this.data.note = note;
          this.data.note.subjectCodes = this.setSubjectCodes(note.operationCategories);

          const photo = this.data.note.photos && this.data.note.photos[0];
          if (photo?.originUrl) {
            this.convertURLtoFile(photo.originUrl).then((file) => {
              const objURL = window.URL.createObjectURL(file);
              this.data.note.photos = [
                { id: photo.id, type: photo.type, ordinal: 1, originUrl: photo.originUrl, resizedUrl: photo.resizedUrl, objURL },
              ];
              this.output.note.next({ ...this.data.note });
              this.output.startFaceScan.next(true);
            });
          } else {
            this.output.note.next({ ...note });
          }
        },
      });
    }

    zip(this.getOperationCategories.execute(), this.getContract.execute(), this.getHospital.execute({ infoTypes: ["PROFILE"] })).subscribe({
      next: ([
        operationCategories,
        {
          plan: { productCodes },
        },
        { id, profile },
      ]) => {
        const availableFaceFitPart = this.setAvailableFaceFitPart(productCodes);

        operationCategories.items.forEach((item, idx) => {
          if (!Boolean(this.data.operationCategories.find((category) => category.subjectCode === item.subjectCode))) {
            this.data.operationCategories.push({
              id: item.id,
              operationName: item.subjectCode,
              subjectCode: item.subjectCode,
              ordinal: idx,
            });
          }
        });

        this.data.surgeryParts = operationCategories.items;
        this.data.surgeryParts.sort((a, b) => {
          if (a.operationName === "매몰법" && b.operationName !== "매몰법") {
            return -1;
          } else if (a.operationName === "절개법" && b.operationName !== "절개법") {
            return -1;
          } else {
            return 1;
          }
        });
        this.data.hospitalId = id;

        this.output.operationCategories.next(this.data.operationCategories);
        this.output.availableFaceFitPart.next(availableFaceFitPart);
        if (profile.faceFitLogoImageUrl) {
          this.output.faceFitLogoUrl.next(profile.faceFitLogoImageUrl);
        }
        this.output.hospitalId.next(this.data.hospitalId);
        setTimeout(() => {
          if (this.data.surgeryParts) {
            this.output.surgeryParts.next(this.data.surgeryParts);
          }
        }, 1500);
      },
      error: (err) => {},
    });
  }

  private updateNote = (complete?: () => void) => {
    if (this.data.noteId) {
      this.uiSystem.loadingHandler.backdropLoading.next(true);

      const photos = this.data.note.photos?.map((photo) => {
        return {
          id: photo.id,
          type: photo.type,
          ordinal: photo.ordinal,
          originUrl: photo.originUrl ? this.removeSignedURL(photo.originUrl) : "",
          resizedUrl: photo.resizedUrl ? this.removeSignedURL(photo.resizedUrl) : "",
        };
      });

      const hospitalCategories = this.data.surgeryParts
        ?.filter((category) => this.data.note.subjectCodes?.some((code) => code === category.subjectCode))
        .map((category) => category.id);

      this.updateConsultingNote
        .execute(this.data.noteId, { ...this.data.note, photos, hospitalCategories })
        .pipe(
          mergeMap(({ consultingNoteId }) => {
            return this.getConsultingNote.execute({ noteId: consultingNoteId });
          }),
        )
        .subscribe({
          next: (note) => {
            note.operationCategories?.forEach((category) => {
              if (!this.data.note.subjectCodes?.some((code) => code === category.subjectCode)) {
                this.data.note.subjectCodes?.push(category.subjectCode);
              }
            });

            this.data.note.subjectCodes = this.setSubjectCodes(note.operationCategories);
            this.data.note = { ...note, photos: this.data.note.photos };
            this.output.note.next({ ...this.data.note });

            this.uiSystem.loadingHandler.backdropLoading.next(false);
            complete && complete();
          },
          error: () => {
            this.uiSystem.loadingHandler.backdropLoading.next(false);
          },
        });
    }
  };

  private convertURLtoFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      const ext = url.split(".").pop();
      const filename = url.split("/").pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], filename!, metadata);
    } catch (err) {
      throw err;
    }
  };

  private removeSignedURL = (signedURL: string) => {
    const [url, _] = signedURL.split("?");
    return url;
  };

  private setSubjectCodes = (operationCategories?: { id: number; operationName: string; subjectCode: SubjectCode }[]): SubjectCode[] => {
    if (operationCategories) {
      const result: SubjectCode[] = [];

      operationCategories.forEach((category) => {
        if (!result.some((s) => s === category.subjectCode)) {
          result.push(category.subjectCode);
        }
      });

      return result;
    } else {
      return [];
    }
  };

  private dataURLToBlob(dataURL: string): Blob {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  private setAvailableFaceFitPart = (productCodes: string[]): Entity.Type.SubjectCode[] => {
    const productCode = Utils.formatProductCode(productCodes);
    let result: Entity.Type.SubjectCode[] = [];

    if (productCode.facefit?.operations?.eyes) {
      result.push("EYES");
    }

    if (productCode.facefit?.operations?.nose) {
      result.push("NOSE");
    }

    if (productCode.facefit?.operations?.facialcontouring) {
      result.push("FACIAL_CONTOURING");
    }

    return result;
  };

  // private CreateConsultationRecording = (noteId: number, recordingFile: File) => {
  //   const sub = this.ucCreateConsultationRecording
  //     .execute({ noteId, record: recordingFile })
  //     .pipe(
  //       mergeMap(({ recordId }) => {
  //         return this.ucCreateConsultingTranscriptions.execute({ recordId });
  //       }),
  //     )
  //     .subscribe({
  //       next: () => {
  //         sub.unsubscribe();
  //       },
  //       error: () => {
  //         sub.unsubscribe();
  //       },
  //     });
  // };
}
