import { injectable } from "inversify";
import { AccountsViewModel } from "./model/AccountsViewModel";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import { Hospital, UI, User } from "@/application/view-data";
import { Subject, zip } from "rxjs";
import Utils from "./util/ViewModelUtil";
import type { CreateAccount } from "@/domain/usecase/hospital/model/CreateAccount";
import type { UpdateAccount } from "@/domain/usecase/hospital/model/UpdateAccount";
import type { GetAccountLoginIdValidation } from "@/domain/usecase/hospital/model/GetAccountLoginIdValidation";
import type { GetUserV2 } from "@/domain/usecase/auth/model/GetUserV2";
import * as Entity from "@/domain/entity";
import type { GetContract } from "@/domain/usecase/hospital/model/GetContract";
@injectable()
export class AccountsViewModelImpl implements AccountsViewModel {
  data: AccountsViewModel["data"] = {
    myRole: null,
    accounts: null,
  };
  output: AccountsViewModel["output"] = {
    isAvailableAddAccount: new Subject<boolean>(),
    myRole: new Subject<Entity.Type.UserRole>(),
    accounts: new Subject<Hospital.Account[] | null>(),
    openAccountEditor: new Subject<{ open: boolean; type: "CREATE" | "UPDATE" | null; account: Hospital.Account | null }>(),
    editingAccount: new Subject<{ open: boolean; type: "CREATE" | "UPDATE" | null; accountForm: User.AccountForm | null }>(),
    isAvaliableLoginId: new Subject<boolean>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly getHospital: GetHospital,
    private readonly getUser: GetUserV2,
    private readonly createAccount: CreateAccount,
    private readonly updateAccount: UpdateAccount,
    private readonly getAccountLoginIdValidation: GetAccountLoginIdValidation,
    private readonly getContract: GetContract,
  ) {
    this.init();
  }

  input: AccountsViewModel["input"] = {
    clickAddAccount: () => {
      if (this.data.myRole === "ADMIN") {
        this.output.editingAccount.next({ open: true, type: "CREATE", accountForm: null });
      } else {
        this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
      }
    },
    clickEditAccount: (type, account) => {
      if (this.data.myRole === "ADMIN") {
        this.output.editingAccount.next({
          open: true,
          type,
          accountForm: {
            userId: account.user.id,
            loginId: account.user.loginId,
            name: account.user.name,
            role: account.user.role,
            newPassword: null,
            password: "",
            confirmPassword: "",
          },
        });
      } else {
        this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
      }
    },
    closeEditor: (type: "CREATE" | "UPDATE") => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: type === "CREATE" ? "작성 중인 내용이 사라집니다." : "변경중인 내용이 사라집니다.",
        confirm: () => {
          this.output.editingAccount.next({ open: false, type: null, accountForm: null });
          this.output.isAvaliableLoginId.next(false);
        },
      });
    },
    clickValidationCheckForLoginId: (loginId) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const sub = this.getAccountLoginIdValidation.execute({ type: "login_id", value: loginId }).subscribe({
        next: ({ exists }) => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          this.output.isAvaliableLoginId.next(!exists);
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
      });
    },
    clickCreateAccount: (accountForm: User.AccountForm) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      if (accountForm.newPassword) {
        const sub = this.createAccount
          .execute({
            loginId: accountForm.loginId,
            loginPassword: accountForm.newPassword,
            name: accountForm.name,
            role: accountForm.role,
          })
          .subscribe({
            next: ({ userId }) => {
              this.init();
              this.output.editingAccount.next({ open: false, type: null, accountForm: null });
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.uiSystem.toastHandler.toast.next({
                message: "계정이 추가되었습니다.",
                position: { vertical: "top", horizontal: "center" },
              });
              sub.unsubscribe();
            },
            error: (error) => {
              if (error?.error_message.include("not allowed to access")) {
                this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
              } else {
                this.uiSystem.errorHandler.alert.next({ message: "알 수 없는 에러가 발생했습니다.\n잠시후 다시 시도해주세요." });
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
    clickUpdateAccount: (accountForm) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      if (accountForm.userId) {
        const sub = this.updateAccount
          .execute({
            userId: accountForm.userId,
            currentLoginPassword: accountForm.password,
            newLoginPassword: accountForm.newPassword,
            name: accountForm.name,
            role: accountForm.role,
            status: "ACTIVATED",
          })
          .subscribe({
            next: ({ userId }) => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.output.editingAccount.next({ open: false, type: null, accountForm: null });
              this.init();
              this.uiSystem.toastHandler.toast.next({
                message: "계정이 수정되었습니다.",
                position: { vertical: "top", horizontal: "center" },
              });
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.errorHandler.alert.next({ message: "알 수 없는 에러가 발생했습니다.\n잠시후 다시 시도해주세요." });
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
  };

  private init() {
    const sub = zip(this.getHospital.execute({ infoTypes: ["ACCOUNTS"] }), this.getUser.execute(), this.getContract.execute()).subscribe({
      next: ([hospital, user, contract]) => {
        this.data.myRole = user.user.role;

        const isAvailableAddAccount = !contract.plan.accounts.every((account) => account.usage !== null);

        this.output.isAvailableAddAccount.next(isAvailableAddAccount);
        this.output.myRole.next(user.user.role);
        if (hospital.accounts) {
          this.data.accounts = hospital.accounts?.map((account) => {
            const createdAt: UI.FormatedDate = {
              date: account.user.createdAt,
              "yyyy-mm-dd hh:mm": Utils.formatDate(account.user.createdAt, "yyyy-mm-dd hh:mm"),
            };

            return { ...account, user: { ...account.user, createdAt: createdAt } };
          });

          this.data.accounts.sort((a, b) => {
            if (a.user.role === "ADMIN" && b.user.role !== "ADMIN") {
              return -1;
            } else if (a.user.role !== "ADMIN" && b.user.role === "ADMIN") {
              return 1;
            } else {
              return 0;
            }
          });

          this.output.accounts.next(this.data.accounts);
        }

        sub.unsubscribe();
      },
      error: (error) => {
        if (error?.error_message === "user status is not activated") {
          this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
        } else {
          this.uiSystem.errorHandler.alert.next({ message: "알 수 없는 에러가 발생했습니다.\n잠시후 다시 시도해주세요." });
        }
        this.uiSystem.loadingHandler.backdropLoading.next(false);
        sub.unsubscribe();
      },
    });
  }
}
