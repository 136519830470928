import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

 
  body {
    font-family: 'SUIT', sans-serif;
    margin: 0px;
    padding: env(safe-area-inset);
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    box-sizing: border-box;
  }

  div{
    font-family: 'SUIT', sans-serif;
  }

  img{
    user-drag: none;
    -webkit-user-drag: none; 
  }

  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
  } 

  button{
    border: none;
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-out-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  textarea {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; 
  }

  ol,ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
  width: 5px; /* 트랙의 너비 */
  height: 5px; /* 수평 스크롤바의 높이 */
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #36766d;
  border-radius: 3px;
  width: 3px; /* thumb의 너비 (실제로는 안 먹히지만 padding으로 조정 가능) */
  box-sizing: border-box;
  border: solid 1px transparent; /* 투명한 테두리를 추가하여 thumb의 실제 너비를 줄입니다 */
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #C3C3C333;
}
`;

export default GlobalStyle;
