import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { DrawConnectorsByMediaPipe } from "@/domain/usecase/classification/model/DrawConnectorsByMediaPipe";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import type { ReqeustFaceLandmark } from "@/domain/usecase/consulting/model/RequestFaceLandmark";
import { Coordinate } from "@view-data/FaceLandmark";
import { FaceFit, UI } from "@/application/view-data";
import { injectable } from "inversify";
import { delay, interval, mergeMap, of, Subject, tap } from "rxjs";
import { EyeGoldenRatio } from "./face-classification/eye/EyeGoldenRatio";
import { FaceScanViewModel } from "./model/FaceScanViewModel";
import { AsymmetricalEye } from "./face-classification/eye/AsymmetricalEye";
import { EyeTail } from "./face-classification/eye/EyeTail";
import { EyesAspectRatio } from "./face-classification/eye/EyeAspectRatio";
import { DrawingFacialLandmarks } from "./face-classification/face/DrawingFacialLandmarks";
import { NoseLengthRatio } from "./face-classification/nose/NoseLengthRatio";
import { NoseHeightRatio } from "./face-classification/nose/NoseHeightRatio";
import { ForeheadNoseAngle } from "./face-classification/nose/ForeheadNoseAngle";
import { NasolabialAngle } from "./face-classification/nose/NasolabialAngle";
import { NoseTipShape } from "./face-classification/nose/NoseTipShape";
import { NoseBridgeWidthRatio } from "./face-classification/nose/NoseBridgeWidthRatio";
import { NoseGuideLine } from "./face-classification/face/NoseGuideLine";
import { FaceShapeType } from "./face-classification/face-contour/FaceShapeType";
import { FaceContourGuideLine } from "./face-classification/face/FaceContourGuideLine";
import { ZygomaticRatio } from "./face-classification/face-contour/ZygomaticRatio";
import { MandibularRatio } from "./face-classification/face-contour/MandibularRatio";
import { FaceContourSymmetryRatio } from "./face-classification/face-contour/SymmetryRatio";
@injectable()
export class FaceScanViewModelImpl implements FaceScanViewModel {
  data: FaceScanViewModel["data"] = {
    landmarks: undefined,
    eyeGoldenRatio: {},
    facialProportionsRatio: {},
    facialSymmetryAngle: {},
    faceFocusAreaCoordinate: {},
    operationParts: [
      { data: { code: "EYES", name: "눈" }, isSelected: true },
      { data: { code: "NOSE", name: "코" }, isSelected: false },
      { data: { code: "FACIAL_CONTOURING", name: "윤곽" }, isSelected: false },
    ],
    eyeScanVisibility: {
      goldenRatio: { before: true, best: true },
      asymmetry: { before: false, best: false },
      aspectRatio: { before: false, best: false },
      tailAngle: { before: false, best: false },
    },
    alignedPhoto: null,
    eyeScanResults: {
      goldenRatio: { before: null, best: "0.5 : 1 : 1 : 1 : 0.5" },
      asymmetry: { before: null, best: "1 : 1" },
      aspectRatio: { before: null, best: "1 : 3 / 1 : 3" },
      eyeTail: { before: null, best: "4° : 4°" },
    },
    eyeClassification: {
      goldenRatio: null,
      asymmetricalEye: null,
      eyeTail: null,
      eyeAspectRatio: null,
    },
    noseClassification: {
      noseLengthRatio: null,
      noseHeightRatio: null,
      foreheadNoseAngle: null,
      nasolabialAngle: null,
      noseTipShape: null,
      noseBridgeWidthRatio: null,
    },
    faceContourClassification: {
      faceShapeType: null,
      zygomaticRatio: null,
      mandibularRatio: null,
      symmetryRatio: null,
    },
    noseScanVisibility: {
      noseLength: { before: true, best: true },
      noseHeight: { before: false, best: false },
      foreheadNoseAngle: { before: false, best: false },
      nasolabialAngle: { before: false, best: false },
      noseTipShape: { before: false, best: false },
      noseBridgeWidth: { before: false, best: false },
    },
    noseScanResults: {
      noseHeight: { before: null, best: "코길이의 67%" },
      noseLength: { before: null, best: "1 : 1" },
      foreheadNoseAngle: { before: null, best: "150° (Female) 148° (Male)" },
      nasolabialAngle: { before: null, best: "95°~100° (Female)\n90°~95° (Male)" },
      noseTipShape: { before: null, best: "2 : 6 : 2, 완만한 갈매기라인" },
      noseBridgeWidth: { before: null, best: "1 : 8 : 1" },
    },
    faceContourVisibility: {
      faceShapeType: { before: true, best: true },
      zygomaticRatio: { before: false, best: false },
      mandibularRatio: { before: false, best: false },
      symmetryRatio: { before: false, best: false },
    },
    faceContourScanResults: {
      faceShapeType: { before: null, best: "70%" },
      zygomaticRatio: { before: null, best: "0.5 : 1 : 1 : 1 : 0.5" },
      mandibularRatio: { before: null, best: "1 : 0.8 / 33% : 67%" },
      symmetryRatio: { before: null, best: `90°:90°, 90°:90°` },
    },
  };

  output: FaceScanViewModel["output"] = {
    landmarks: new Subject<Coordinate[][]>(),
    alignedPhoto: new Subject<string>(),
    faceFocusAreaCoordinate: new Subject<{
      leftTop?: { x: number; y: number };
      rightTop?: { x: number; y: number };
      leftBottom?: { x: number; y: number };
      rightBottom?: { x: number; y: number };
    }>(),
    scanCompletion: new Subject<boolean>(),
    operationParts: new Subject<UI.SelectableItem<{ code: "EYES" | "NOSE" | "FACIAL_CONTOURING"; name: "눈" | "코" | "윤곽" }>[]>(),
    eyeScanVisibility: new Subject<FaceFit.EyeScanVisibility>(),
    eyeScanResults: new Subject<FaceFit.EyeScanResults>(),
    eyeScanResultsDescription: new Subject<FaceFit.FaceScanResultDescription | null>(),
    noseScanResultsDescription: new Subject<FaceFit.FaceScanResultDescription | null>(),
    noseScanVisibility: new Subject<FaceFit.NoseScanVisibility>(),
    noseScanResults: new Subject<FaceFit.NoseScanResults>(),
    faceContourScanResults: new Subject<FaceFit.FaceContourScanResults>(),
    faceContourVisibility: new Subject<FaceFit.FaceContourVisibility>(),
    faceContourScanResultsDescription: new Subject<FaceFit.FaceScanResultDescription | null>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly getFaceLandmarksByGCS: GetFaceLandmarks,
    private readonly drawFaceLandmarkByMediaPipe: DrawConnectorsByMediaPipe,
    private readonly requestFaceLandmark: ReqeustFaceLandmark,
    private readonly getAIAnalysis: GetAIAnalysis,
  ) {}

  input: FaceScanViewModel["input"] = {
    clickOperationPart: (code) => {
      this.data.operationParts = this.data.operationParts.map((operationPart) => {
        return { ...operationPart, isSelected: operationPart.data.code === code };
      });
      this.output.operationParts.next(this.data.operationParts);
    },
    clickEyeScanContentVisibilityOption: (contentType, canvasType) => {
      this.data.eyeScanVisibility[contentType][canvasType] = !this.data.eyeScanVisibility[contentType][canvasType];
      this.output.eyeScanVisibility.next({ ...this.data.eyeScanVisibility });

      const eyeScanDescription = this.getEyeScanResultsDescription();
      this.output.eyeScanResultsDescription.next(eyeScanDescription);
    },
    clickNoseScanContentVisibilityOption: (contentType, canvasType) => {
      this.data.noseScanVisibility[contentType][canvasType] = !this.data.noseScanVisibility[contentType][canvasType];
      this.output.noseScanVisibility.next({ ...this.data.noseScanVisibility });

      const noseScanDescription = this.getNoseScanResultsDescription();
      this.output.noseScanResultsDescription.next(noseScanDescription);
    },

    clickFaceContourScanContentVisibilityOption: (contentType, canvasType) => {
      this.data.faceContourVisibility[contentType][canvasType] = !this.data.faceContourVisibility[contentType][canvasType];
      this.output.faceContourVisibility.next({ ...this.data.faceContourVisibility });

      const faceContourDescription = this.getFaceContourScanResultsDescription();
      this.output.faceContourScanResultsDescription.next(faceContourDescription);
    },

    clickEyeScanContentQuickView: (type: "BEFORE" | "BEST") => {
      const { goldenRatio, asymmetry, aspectRatio, tailAngle } = this.data.eyeScanVisibility;

      switch (type) {
        case "BEFORE":
          if (goldenRatio.before && asymmetry.before && aspectRatio.before && tailAngle.before) {
            this.data.eyeScanVisibility.aspectRatio.before = false;
            this.data.eyeScanVisibility.goldenRatio.before = false;
            this.data.eyeScanVisibility.asymmetry.before = false;
            this.data.eyeScanVisibility.tailAngle.before = false;
          } else {
            this.data.eyeScanVisibility.aspectRatio.before = true;
            this.data.eyeScanVisibility.goldenRatio.before = true;
            this.data.eyeScanVisibility.asymmetry.before = true;
            this.data.eyeScanVisibility.tailAngle.before = true;
          }

          break;
        case "BEST":
          if (goldenRatio.best && asymmetry.best && aspectRatio.best && tailAngle.best) {
            this.data.eyeScanVisibility.aspectRatio.best = false;
            this.data.eyeScanVisibility.goldenRatio.best = false;
            this.data.eyeScanVisibility.asymmetry.best = false;
            this.data.eyeScanVisibility.tailAngle.best = false;
          } else {
            this.data.eyeScanVisibility.aspectRatio.best = true;
            this.data.eyeScanVisibility.goldenRatio.best = true;
            this.data.eyeScanVisibility.asymmetry.best = true;
            this.data.eyeScanVisibility.tailAngle.best = true;
          }
          break;
      }

      this.output.eyeScanVisibility.next({ ...this.data.eyeScanVisibility });
    },

    clickNoseScanContentQuickView: (type) => {
      const { noseLength, noseHeight, foreheadNoseAngle, nasolabialAngle, noseBridgeWidth, noseTipShape } = this.data.noseScanVisibility;

      switch (type) {
        case "BEFORE":
          if (
            noseLength.before &&
            noseHeight.before &&
            foreheadNoseAngle.before &&
            nasolabialAngle.before &&
            noseBridgeWidth.before &&
            noseTipShape.before
          ) {
            this.data.noseScanVisibility.noseLength.before = false;
            this.data.noseScanVisibility.noseHeight.before = false;
            this.data.noseScanVisibility.foreheadNoseAngle.before = false;
            this.data.noseScanVisibility.nasolabialAngle.before = false;
            this.data.noseScanVisibility.noseTipShape.before = false;
            this.data.noseScanVisibility.noseBridgeWidth.before = false;
          } else {
            this.data.noseScanVisibility.noseLength.before = true;
            this.data.noseScanVisibility.noseHeight.before = true;
            this.data.noseScanVisibility.foreheadNoseAngle.before = true;
            this.data.noseScanVisibility.nasolabialAngle.before = true;
            this.data.noseScanVisibility.noseTipShape.before = true;
            this.data.noseScanVisibility.noseBridgeWidth.before = true;
          }

          break;
        case "BEST":
          if (
            noseLength.best &&
            noseHeight.best &&
            foreheadNoseAngle.best &&
            nasolabialAngle.best &&
            noseBridgeWidth.best &&
            noseTipShape.best
          ) {
            this.data.noseScanVisibility.noseLength.best = false;
            this.data.noseScanVisibility.noseHeight.best = false;
            this.data.noseScanVisibility.foreheadNoseAngle.best = false;
            this.data.noseScanVisibility.nasolabialAngle.best = false;
            this.data.noseScanVisibility.noseBridgeWidth.best = false;
            this.data.noseScanVisibility.noseTipShape.best = false;
          } else {
            this.data.noseScanVisibility.foreheadNoseAngle.best = true;
            this.data.noseScanVisibility.noseLength.best = true;
            this.data.noseScanVisibility.noseHeight.best = true;
            this.data.noseScanVisibility.nasolabialAngle.best = true;
            this.data.noseScanVisibility.noseBridgeWidth.best = true;
            this.data.noseScanVisibility.noseTipShape.best = true;
          }
          break;
      }

      this.output.noseScanVisibility.next({ ...this.data.noseScanVisibility });
    },

    clickFaceContourScanContentQuickView: (type) => {
      const { faceShapeType, zygomaticRatio, mandibularRatio, symmetryRatio } = this.data.faceContourVisibility;

      switch (type) {
        case "BEFORE":
          if (faceShapeType.before && zygomaticRatio.before && mandibularRatio.before && symmetryRatio.before) {
            this.data.faceContourVisibility.faceShapeType.before = false;
            this.data.faceContourVisibility.zygomaticRatio.before = false;
            this.data.faceContourVisibility.mandibularRatio.before = false;
            this.data.faceContourVisibility.symmetryRatio.before = false;
          } else {
            this.data.faceContourVisibility.faceShapeType.before = true;
            this.data.faceContourVisibility.zygomaticRatio.before = true;
            this.data.faceContourVisibility.mandibularRatio.before = true;
            this.data.faceContourVisibility.symmetryRatio.before = true;
          }

          break;
        case "BEST":
          if (faceShapeType.best && zygomaticRatio.best && mandibularRatio.best && symmetryRatio.best) {
            this.data.faceContourVisibility.faceShapeType.best = false;
            this.data.faceContourVisibility.zygomaticRatio.best = false;
            this.data.faceContourVisibility.mandibularRatio.best = false;
            this.data.faceContourVisibility.symmetryRatio.best = false;
          } else {
            this.data.faceContourVisibility.mandibularRatio.best = true;
            this.data.faceContourVisibility.faceShapeType.best = true;
            this.data.faceContourVisibility.zygomaticRatio.best = true;
            this.data.faceContourVisibility.symmetryRatio.best = true;
          }
          break;
      }

      this.output.faceContourVisibility.next({ ...this.data.faceContourVisibility });
    },
  };

  event: FaceScanViewModel["event"] = {
    onCreateLandmark: (note) => {
      const fontalPhoto = note.photos?.find((photo) => photo.type === "FRONTAL");
      if (note.id && fontalPhoto?.id) {
        const sub = this.requestFaceLandmark.execute({ noteId: note.id, photoId: fontalPhoto.id }).subscribe({
          next: ({ analysisId }) => {
            if (note.id) {
              this.getFaceLandmark(note.id, analysisId);
            }

            sub.unsubscribe();
          },
          error: () => {
            sub.unsubscribe();
          },
        });
      }
    },
    onGetLandmark: (landmarkJSONURL) => {
      const sub = this.getFaceLandmarksByGCS.execute({ url: landmarkJSONURL }).subscribe({
        next: ({ landmarks }) => {
          this.data.landmarks = landmarks;
          this.output.landmarks.next(this.data.landmarks);
          sub.unsubscribe();
        },
        error: (err) => {
          sub.unsubscribe();
        },
      });
    },

    onSetFaceLandmark: (photo, canvas) => {
      if (this.data.landmarks) {
        const drawingFacialLandmarks = new DrawingFacialLandmarks({
          imageEle: photo,
          canvasEle: canvas,
          normalizedCoordinate: this.data.landmarks,
        });

        drawingFacialLandmarks.drawLandmarkPoints();
      }
    },

    onStartSubjectSetup: (subjectCode) => {
      this.data.operationParts = this.data.operationParts.map((operationPart) => {
        return { ...operationPart, isSelected: operationPart.data.code === subjectCode };
      });
      this.output.operationParts.next(this.data.operationParts);
    },

    face: {
      onSetDrawingFacialLandmarks: (photo, canvas) => {
        if (this.data.landmarks) {
          const drawingFacialLandmarks = new DrawingFacialLandmarks({
            imageEle: photo,
            canvasEle: canvas,
            normalizedCoordinate: this.data.landmarks,
          });

          drawingFacialLandmarks.drawSideFace();
        }
      },
      onSetNoseGuideLine: (photo, noseCanvas, guideLine) => {
        if (this.data.landmarks) {
          const guideLineCanvas = new NoseGuideLine({
            config: {
              imageEle: photo,
              canvasEle: noseCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            guideLineCanvas: guideLine,
          });

          guideLineCanvas.drawGuideLine();
        }
      },
      onSetFaceContour: (photo, faceContourCanvas) => {
        if (this.data.landmarks) {
          const guideLineCanvas = new FaceContourGuideLine({
            config: { imageEle: photo, canvasEle: faceContourCanvas, normalizedCoordinate: this.data.landmarks },
            guideLineCanvas: faceContourCanvas,
          });

          guideLineCanvas.drawGuideLine();
        }
      },
    },

    eye: {
      onSetGoldenRatio: (canvasConfig) => {
        if (this.data.landmarks && this.data.alignedPhoto) {
          const beforeCanvas = new EyeGoldenRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestCanvas = new EyeGoldenRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.goldenRatio = beforeCanvas;
          this.data.eyeScanResults.goldenRatio.before = `${beforeCanvas.ratio.우얼굴} : ${beforeCanvas.ratio.우눈} : ${beforeCanvas.ratio.중안} : ${beforeCanvas.ratio.좌눈} : ${beforeCanvas.ratio.좌얼굴}`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });

          const eyeScanDescription = this.getEyeScanResultsDescription();
          this.output.eyeScanResultsDescription.next(eyeScanDescription);
        }
      },
      onSetAsymmetricalRatio: (canvasConfig) => {
        if (this.data.landmarks && this.data.alignedPhoto) {
          const beforeCanvas = new AsymmetricalEye({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          const bestCanvas = new AsymmetricalEye({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.asymmetricalEye = beforeCanvas;
          this.data.eyeScanResults.asymmetry.before = `${beforeCanvas.ratio.우} : ${beforeCanvas.ratio.좌}`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });
        }
      },
      onSetEyeTailRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new EyeTail({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          const bestCanvas = new EyeTail({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.eyeTail = beforeCanvas;
          this.data.eyeScanResults.eyeTail.before = `${beforeCanvas.eyeTailAngle.왼눈}° : ${beforeCanvas.eyeTailAngle.우눈}°`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });
        }
      },
      onSetEyeAspectRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new EyesAspectRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          const bestCanvas = new EyesAspectRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.eyeAspectRatio = beforeCanvas;
          this.data.eyeScanResults.aspectRatio.before = `${beforeCanvas.ratio.right.높이} : ${beforeCanvas.ratio.right.길이} / ${beforeCanvas.ratio.left.높이} : ${beforeCanvas.ratio.left.길이}`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });
        }
      },
    },

    nose: {
      onSetNoseLength: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new NoseLengthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestCanvas = new NoseLengthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeClassification.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.noseClassification.noseLengthRatio = beforeClassification;
          this.data.noseScanResults.noseLength.before = `${beforeClassification.ratio.noseRatio} : ${beforeClassification.ratio.jawRatio}`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });
        }
      },
      onSetNoseHeight: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NoseHeightRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestClassification = new NoseHeightRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.noseHeightRatio = beforeCanvas;
          this.data.noseScanResults.noseHeight.before = `1 : ${parseFloat((beforeCanvas.ratio.noseHeight * 100).toFixed(2)).toString()}%`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });
        }
      },
      onSetForeheadNoseAngle: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new ForeheadNoseAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestClassification = new ForeheadNoseAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.foreheadNoseAngle = beforeCanvas;
          this.data.noseScanResults.foreheadNoseAngle.before = `${beforeCanvas.ratio.angle}°`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });
        }
      },
      onSetNasolabialAngle: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NasolabialAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestClassification = new NasolabialAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();
          this.data.noseClassification.nasolabialAngle = beforeCanvas;
          this.data.noseScanResults.nasolabialAngle.before = `${beforeCanvas.ratio.angle}°`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });
        }
      },
      onSetNoseTipShape: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NoseTipShape({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });
          const bestClassification = new NoseTipShape({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.noseTipShape = beforeCanvas;
          this.data.noseScanResults.noseTipShape.before = `${(beforeCanvas.ratio.right * 10).toFixed(1)} : ${(
            beforeCanvas.ratio.center * 10
          ).toFixed(1)} : ${(beforeCanvas.ratio.left * 10).toFixed(1)}`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });
        }
      },
      onSetNoseBridgeWidth: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NoseBridgeWidthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });
          const bestClassification = new NoseBridgeWidthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });
          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.noseBridgeWidthRatio = beforeCanvas;
          this.data.noseScanResults.noseBridgeWidth.before = `${(beforeCanvas.ratio.right * 10).toFixed()} : ${(
            beforeCanvas.ratio.center * 10
          ).toFixed(2)} : ${(beforeCanvas.ratio.left * 10).toFixed(2)}`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });
        }
      },
    },
    faceContour: {
      onSetFaceShapeType: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new FaceShapeType({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new FaceShapeType({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.faceContourClassification.faceShapeType = beforeClassification;
          this.data.faceContourScanResults.faceShapeType.before = `${parseFloat(
            (beforeClassification.ratio.bottom * 100).toFixed(2),
          ).toString()}%`;
          this.output.faceContourScanResults.next({ ...this.data.faceContourScanResults });
        }
      },
      onSetFrontalFaceRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new ZygomaticRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new ZygomaticRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.faceContourClassification.zygomaticRatio = beforeClassification;
          this.data.faceContourScanResults.zygomaticRatio.before = `${beforeClassification.ratio.rightFace} : ${beforeClassification.ratio.rightEye} : ${beforeClassification.ratio.midFace} : ${beforeClassification.ratio.leftEye} : ${beforeClassification.ratio.leftFace}`;
          this.output.faceContourScanResults.next({ ...this.data.faceContourScanResults });
        }
      },
      onSetJawlineRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new MandibularRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new MandibularRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.faceContourClassification.mandibularRatio = beforeClassification;
          this.data.faceContourScanResults.mandibularRatio.before = `${1} : ${beforeClassification.ratio.face.ratio.bottom} / ${
            beforeClassification.ratio.jaw.ratio.top * 100
          }% : ${beforeClassification.ratio.jaw.ratio.bottom * 100}%`;
          this.output.faceContourScanResults.next({ ...this.data.faceContourScanResults });
        }
      },
      onSetSymmetryRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new FaceContourSymmetryRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new FaceContourSymmetryRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();
          this.data.faceContourClassification.symmetryRatio = beforeClassification;
          this.data.faceContourScanResults.symmetryRatio.before = `${beforeClassification.angle.top.right}° : ${beforeClassification.angle.top.left}°, ${beforeClassification.angle.bottom.right}° : ${beforeClassification.angle.bottom.left}°`;
        }
      },
    },
  };

  private getFaceLandmark = (noteId: number, analysisId: number) => {
    let requestCount = 0;
    const sub = interval(2000)
      .pipe(
        mergeMap(() => {
          return this.getAIAnalysis.execute({ noteId });
        }),
        mergeMap(({ faceLandmarks }) => {
          requestCount += 1;
          const facelandmark = faceLandmarks.find((faceLandmark) => faceLandmark.id === analysisId);

          if (facelandmark) {
            if (facelandmark.status === "SUCCESS" && facelandmark.result?.faceLandmarksJSONURL && facelandmark.result.alignedImageURL) {
              this.data.alignedPhoto = facelandmark.result.alignedImageURL;
              this.output.alignedPhoto.next(facelandmark.result.alignedImageURL);
              this.event.onGetLandmark(facelandmark.result?.faceLandmarksJSONURL);

              return of(true).pipe(
                delay(3000),
                tap(() => {
                  this.output.scanCompletion.next(true);
                  sub.unsubscribe();
                }),
              );
            } else if (facelandmark.status === "FAIL") {
              this.uiSystem.errorHandler.alert.next({ message: `Face Scan을 실패하였습니다\n정면 사진을 다시 업로드해주세요.` });
              sub.unsubscribe();
              return of(null);
            }
          }

          if (requestCount === 14) {
            sub.unsubscribe();
          }

          return of(null);
        }),
      )
      .subscribe({
        error: () => {
          sub.unsubscribe();
        },
      });
  };
  private getEyeScanResultsDescription = (): FaceFit.FaceScanResultDescription | null => {
    const eyeKeysWithTrueValues = this.getVisibleKeys(
      this.data.eyeScanVisibility as { ["goldenRatio"]: { before: boolean; best: boolean } },
    );

    if (eyeKeysWithTrueValues.length > 1) {
      return null;
    }

    const descriptions: { [key: string]: FaceFit.FaceScanResultDescription } = {
      goldenRatio: {
        scanResults: [
          {
            typeName: "눈 좌우여백",
            metrics: [
              { position: "Right", value: (this.data.eyeClassification.goldenRatio?.ratio.우얼굴 ?? 0) - 0.5 },
              { position: "Left", value: (this.data.eyeClassification.goldenRatio?.ratio.좌얼굴 ?? 0) - 0.5 },
            ],
          },
          {
            typeName: "눈 사이여백",
            metrics: [{ position: null, value: (this.data.eyeClassification.goldenRatio?.ratio.중안 ?? 0) - 1 }],
          },
        ],
        description: "+수치만큼 여백을 축소조정하여 시원한 눈매로 개선",
      },
      asymmetry: {
        scanResults: [
          {
            typeName: "눈 높이차이",
            metrics: [{ position: null, value: (this.data.eyeClassification.asymmetricalEye?.ratio.좌 ?? 0) - 1 }],
          },
        ],
        description: "차이가 심한 경우 눈매교정 고려",
      },
      aspectRatio: {
        scanResults: [
          {
            typeName: "종횡비",
            metrics: [
              { position: "Right", value: (this.data.eyeClassification.eyeAspectRatio?.ratio.right.길이 ?? 0) - 3 },
              { position: "Left", value: (this.data.eyeClassification.eyeAspectRatio?.ratio.left.길이 ?? 0) - 3 },
            ],
          },
        ],
        description: `-값 만큼 길이조정하여 이상적인 비율로 개선\n+인경우 높이조정으로 이상적인 비율로 개선`,
      },
      tailAngle: {
        scanResults: [
          {
            typeName: "눈꼬리 각도",
            metrics: [
              { position: "Right", value: (this.data.eyeClassification.eyeTail?.eyeTailAngle.왼눈 ?? 0) - 4 },
              { position: "Left", value: (this.data.eyeClassification.eyeTail?.eyeTailAngle.우눈 ?? 0) - 4 },
            ],
          },
        ],
        description: "기준각도로 개선하여 순한 인상으로 조정",
      },
    };

    return descriptions[eyeKeysWithTrueValues[0]] || null;
  };

  private getNoseScanResultsDescription = () => {
    const noseKeysWithTrueValues = this.getVisibleKeys(
      this.data.noseScanVisibility as { ["noseLength"]: { before: boolean; best: boolean } },
    );

    if (noseKeysWithTrueValues.length > 1) {
      return null;
    }

    const descriptions: { [key: string]: FaceFit.FaceScanResultDescription } = {
      noseLength: {
        scanResults: [
          {
            typeName: "코길이",
            metrics: [{ position: null, value: (this.data.noseClassification.noseLengthRatio?.ratio.jawRatio ?? 0) - 1 }],
          },
        ],
        description: "구각점과 턱끝까지 길이와 1:1일때 이상적인 코길이로 차이가 많이 날경우 하관윤곽을 먼저 교정할 필요가 있음",
      },
      noseHeight: {
        scanResults: [
          {
            typeName: "코끝 높이",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.noseHeightRatio?.ratio.noseHeight ?? 0) * 100 - 67,
                unit: "%",
              },
            ],
          },
        ],
        description: "67% 기준에서 오차범위만큼 코끝교정으로 이상적인 높이로 교정",
      },
      foreheadNoseAngle: {
        scanResults: [
          {
            typeName: "콧대 각도(비전두각)",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.foreheadNoseAngle?.ratio.angle ?? 0) - 150,
                unit: "°",
              },
            ],
          },
        ],
        description:
          "비전두각을 기준보다 전방으로 전진하여 각도가 넓어지면 코끝높이가 감소되어 코가 길어보이고 후방으로 이동시켜 기준각도보다 좁아지면 코끝이 올라가 코길이를 짧아보이게 교정가능",
      },
      nasolabialAngle: {
        scanResults: [
          {
            typeName: "비순각",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.nasolabialAngle?.ratio.angle ?? 0) - 97,
                unit: "°",
              },
            ],
          },
        ],
        description: "기준각도보다 낮은 경우 코끝을 올려 복코를 조정 기준보다 높은경우 코끝을 낮춰서 콧구멍이 덜 보이도록 조정",
      },
      noseTipShape: {
        scanResults: [
          {
            typeName: "코끝,비주모양",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.noseTipShape?.ratio.right ?? 0) * 10 - 2,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseTipShape?.ratio.center ?? 0) * 10 - 6,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseTipShape?.ratio.left ?? 0) * 10 - 2,
              },
            ],
          },
        ],
        description:
          "비첨윤곽점을 기준만큼 보정해서 날렵한모양으로 개선 , 비익연과 비주의 윤곽이 부드러운 갈매기 모양 기준선에서 부족한 비주를 보충해서 개선",
      },
      noseBridgeWidth: {
        scanResults: [
          {
            typeName: "콧대폭 비율",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.noseBridgeWidthRatio?.ratio.right ?? 0) * 10 - 1,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseBridgeWidthRatio?.ratio.center ?? 0) * 10 - 8,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseBridgeWidthRatio?.ratio.left ?? 0) * 10 - 1,
              },
            ],
          },
        ],
        description: "콧대폭의 완만한 곡선으로 개선, 콧대폭이 더 넓은경우, 콧볼기준선이 내안사이보다 크다면 콧볼축소 고려",
      },
    };
    return descriptions[noseKeysWithTrueValues[0]] || null;
  };

  private getFaceContourScanResultsDescription = (): FaceFit.FaceScanResultDescription | null => {
    const faceContourKeysWithTrueValues = this.getVisibleKeys(
      this.data.faceContourVisibility as { ["faceShapeType"]: { before: boolean; best: boolean } },
    );

    if (faceContourKeysWithTrueValues.length > 1) {
      return null;
    }

    const descriptions: { [key: string]: FaceFit.FaceScanResultDescription } = {
      faceShapeType: {
        scanResults: [
          {
            typeName: "정면얼굴형",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.faceShapeType?.ratio.bottom ?? 0) * 100 - 70, unit: "%" },
            ],
          },
        ],
        description: "양턱간의 넓이는 양쪽 과골사이널비이 70%가 적절하며 기준보다 넓은경우 사각턱 축소, 지방흡입으로 비율 개선",
      },
      zygomaticRatio: {
        scanResults: [
          {
            typeName: "정면얼굴형",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.rightFace ?? 0) - 0.5 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.rightEye ?? 0) - 1 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.midFace ?? 0) - 1 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.leftEye ?? 0) - 1 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.leftFace ?? 0) - 0.5 },
            ],
          },
        ],
        description:
          "눈여백 라인보다 광대라인이 돌출된경우 광대축소로 옆비율은 줄이고 앞쪽으로 볼륨을 주어 얼굴이 작아보이도록 개선. 눈꼬리가 끝난는 직선라인보다 사각턱끝라인이 밖에 위치한경우 지방흡입과 사각턱축소로 개선. 교근 발달이 원인이라면 보톡스로 개선",
      },
      mandibularRatio: {
        scanResults: [
          {
            typeName: "하악비율",
            metrics: [{ position: null, value: (this.data.faceContourClassification.mandibularRatio?.ratio.face.ratio.bottom ?? 0) - 0.8 }],
          },
          {
            typeName: "인중:하관",
            metrics: [
              {
                position: null,
                value: (this.data.faceContourClassification.mandibularRatio?.ratio.jaw.ratio.top ?? 0) * 100 - 33,
                unit: "%",
              },
              {
                position: null,
                value: (this.data.faceContourClassification.mandibularRatio?.ratio.jaw.ratio.bottom ?? 0) * 100 - 67,
                unit: "%",
              },
            ],
          },
        ],
        description: `중안부 1/3과 아래 1/3의 비율은 1:0.8을 기준으로 작을수록 동안.인중과 하악비율을 체크하여 작은턱은 턱끝을 전방으로 이동하여 비율을 맞춰주고 긴턱일경우 윗턱과 함께 하악비율을 개선`,
      },
      symmetryRatio: {
        scanResults: [
          {
            typeName: "상안",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.top.right ?? 0) - 90, unit: "°" },
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.top.left ?? 0) - 90, unit: "°" },
            ],
          },
          {
            typeName: "하안",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.bottom.right ?? 0) - 90, unit: "°" },
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.bottom.left ?? 0) - 90, unit: "°" },
            ],
          },
        ],
        description: "비대칭의 원인이 골격적인지 연조직의 문제인지 구분하여 대칭성을 교정",
      },
    };

    return descriptions[faceContourKeysWithTrueValues[0]] || null;
  };

  private getVisibleKeys = (scanVisibility: { [key: string]: { before: boolean; best: boolean } }): string[] => {
    const result: string[] = [];

    Object.keys(scanVisibility).forEach((key) => {
      if (scanVisibility[key].before || scanVisibility[key].best) {
        result.push(key);
      }
    });

    return result;
  };
}
