import { DoctorInspectStatus, DoctorInspectType, DoctorRank, DoctorSubjectCode, DoctorType, ViewData } from "@/application/view-data";
import {
  Doctor,
  DoctorLastSubmission,
  DoctorSubjects,
  DoctorSubmission,
  DoctorSubmissionInspectType,
} from "@/domain/entity/doctor/doctor.entity";
import { SubjectCode } from "@/domain/entity/hospital/hospital.entity";
import type { GetNotices } from "@/domain/usecase/notice/model/GetNotices";
import { HomeViewModel } from "@view-model/model/HomeViewModel";
import { injectable } from "inversify";
import { Subject } from "rxjs";

@injectable()
export class HomeViewModelImpl implements HomeViewModel {
  data: {
    list: ViewData.NoticeListDisplayOutput;
    totalCount: number;
    page: number;
    limit: number;
    doctorList: ViewData.DoctorListDisplayOutput;
    doctorCount: number;
    doctorPage: number;
    doctorLimit?: number;
    commentList: ViewData.CommentListDisplayLayout;
    commentCount: number;
    commentPage: number;
    commentLimit?: number;
    confirmList: ViewData.PatientConfirmListDisplayLayout;
    confirmCount: number;
    confirmPage: number;
    confirmLimit?: number;
  };

  input: {
    onClickPagination: (page: number) => void;
    // onLoadDoctors: (page: number) => void;
    // onLoadWaitingConfirms: (doctorId: number) => void;
    // onLoadTotalComments: (doctorId: number) => void;
    // fetchPartnerFeedback: () => void;
  };

  output = {
    displayNotices: new Subject<ViewData.NoticeListDisplayOutput>(),
    displayDoctors: new Subject<ViewData.DoctorListDisplayOutput>(),
    displayComments: new Subject<ViewData.Comment[]>(),
    displayConfirms: new Subject<ViewData.PatientConfirmListDisplayLayout>(),
    displayWaitingConfirms: new Subject<ViewData.PatientConfirm[]>(),
    displayPartnerFeedback: new Subject<ViewData.PartnerFeedback>(),
  };

  route = {
    toFaceFit: new Subject<{ isMobile: boolean }>(),
    toRecentConsultations: new Subject<void>(),
  };

  constructor(private readonly id: number, private readonly accountType: string, private readonly ucGetNotices: GetNotices) {
    this.data = {
      list: {
        notices: [],
        pagination: {
          page: 1,
          count: 0,
          limit: 10,
        },
      },
      totalCount: 0,
      page: 1,
      limit: 10,
      doctorList: {
        items: [],
        isLast: false,
        last: false,
        page: 0,
        size: 0,
        total: 20,
      },
      doctorCount: 0,
      doctorPage: 1,
      doctorLimit: 30,
      commentList: {
        comments: [],
        pagination: {
          page: 1,
          count: 0,
          limit: 30,
        },
      },
      commentCount: 0,
      commentPage: 1,
      commentLimit: 30,
      confirmList: {
        patientConfirms: [],
        pagination: {
          page: 1,
          count: 0,
          limit: 30,
        },
      },
      confirmCount: 0,
      confirmPage: 1,
      confirmLimit: 30,
    };

    this.output = {
      displayNotices: new Subject<ViewData.NoticeListDisplayOutput>(),
      displayDoctors: new Subject<ViewData.DoctorListDisplayOutput>(),
      displayComments: new Subject<ViewData.Comment[]>(),
      displayConfirms: new Subject<ViewData.PatientConfirmListDisplayLayout>(),
      displayWaitingConfirms: new Subject<ViewData.PatientConfirm[]>(),
      displayPartnerFeedback: new Subject<ViewData.PartnerFeedback>(),
    };

    this.input = {
      onClickPagination: (page: number) => {
        this.data.page = page;
        this.ucGetNotices
          .execute({
            page: this.data.page,
            size: this.data.limit,
          })
          .subscribe((output) => {
            this.output.displayNotices.next({
              notices: output.items.map((item) => ({
                id: item.id,
                title: item.title,
                context: item.content,
                created: item.createdAt.toDateString(),
              })),
              pagination: {
                page: output.page,
                count: output.total,
                limit: output.size,
              },
            });
          });
      },
    };

    this.ucGetNotices
      .execute({
        page: this.data.page,
        size: this.data.limit,
      })
      .subscribe((output) => {
        this.output.displayNotices.next({
          notices: output.items.map((item) => ({
            id: item.id,
            title: item.title,
            context: item.content,
            created: item.createdAt.toDateString(),
          })),
          pagination: {
            page: output.page,
            count: output.total,
            limit: output.size,
          },
        });
      });
  }

  private mapEntityDoctorToViewDataDoctor = (doctor: Doctor & DoctorSubjects & DoctorLastSubmission): ViewData.Doctor => {
    return {
      id: doctor.id,
      partnerId: doctor.partnerId,
      hospitalName: doctor.hospitalName,
      status: doctor.status,
      type: this.mapToDoctorType(doctor.type),
      rank: this.mapToDoctorRank(doctor.rank),
      profile: doctor.profile,
      name: doctor.name,
      description: doctor.description ?? "",
      specialty: doctor.specialty ?? "",
      experienceYears: doctor.experienceYears,
      phoneNumber: doctor.phoneNumber ?? "",
      exposureOrdinal: doctor.exposureOrdinal.toString(),
      loginId: doctor.loginId,
      createdAt: doctor.createdAt.toISOString(),
      privacyAgreedAt: doctor.privacyAgreedAt.toISOString(),
      registeredAt: doctor.registeredAt.toISOString(),
      deletedAt: doctor.deletedAt.toISOString(),
      subjects: doctor.subjects.map(this.mapToDoctorSubjectCode),
      certificatePhotos: [],
      lastSubmission: this.mapToLastSubmission(doctor.lastSubmission),
      briefHistory: [],
    };
  };

  private mapToInspectType = (inspectType: DoctorSubmissionInspectType): DoctorInspectType => {
    switch (inspectType) {
      case "CREATE":
        return DoctorInspectType.CREATE;
      case "UPDATE_INFORMATION":
        return DoctorInspectType.UPDATE_INFORMATION;
      case "REGISTER_ACCOUNT":
        return DoctorInspectType.REGISTER_ACCOUNT;
      case "DELETE":
        return DoctorInspectType.DELETE;
      case "RESET_ACCOUNT":
        return DoctorInspectType.RESET_ACCOUNT;
      case "UPDATE_PASSWORD":
        return DoctorInspectType.UPDATE_PASSWORD;
      case "UPDATE_ACCOUNT":
        return DoctorInspectType.UPDATE_ACCOUNT;
      default:
        throw "invalid inspect-status";
    }
  };

  private mapToInspectStatus = (inspectStatus: DoctorSubmission["inspectStatus"]): DoctorInspectStatus => {
    switch (inspectStatus) {
      case "APPROVED":
        return DoctorInspectStatus.APPROVED;
      case "PENDING":
        return DoctorInspectStatus.PENDING;
      case "REJECTED":
        return DoctorInspectStatus.REJECTED;
    }
  };

  private mapToLastSubmission = (lastSubmission: DoctorLastSubmission["lastSubmission"]) => {
    if (lastSubmission) {
      return {
        id: lastSubmission.id,
        partnerDoctorId: lastSubmission.partnerDoctorId,
        inspectType: this.mapToInspectType(lastSubmission.inspectType),
        inspectStatus: this.mapToInspectStatus(lastSubmission.inspectStatus),
        requestedFrom: lastSubmission.requestedFrom,
        requestedBy: lastSubmission.requestedBy,
        requestedAt: lastSubmission.requestedAt.toISOString(),
        processedComment: lastSubmission.processedComment,
        processedFrom: lastSubmission.processedFrom,
        processedBy: lastSubmission.processedBy,
        processedAt: lastSubmission.processedAt?.toISOString() ?? null,
      };
    }
  };

  private mapToDoctorSubjectCode = (subjectCode: SubjectCode): DoctorSubjectCode => {
    switch (subjectCode) {
      case "EYES":
        return DoctorSubjectCode.EYES;
      case "NOSE":
        return DoctorSubjectCode.NOSE;
      case "FACIAL_CONTOURING":
        return DoctorSubjectCode.FACIAL_CONTOURING;
      case "LIPS":
        return DoctorSubjectCode.LIPS;
      case "LIFTING":
        return DoctorSubjectCode.LIFTING;
      case "SKINCARE":
        return DoctorSubjectCode.SKINCARE;
      case "FILLER":
        return DoctorSubjectCode.FILLER;
      case "BREAST":
        return DoctorSubjectCode.BREAST;
      case "MICRO_LIPO_INJECTION":
        return DoctorSubjectCode.MICRO_LIPO_INJECTION;
      case "LIPO_SUCTION":
        return DoctorSubjectCode.LIPO_SUCTION;
    }
  };

  private mapToDoctorType = (doctorType: Doctor["type"]): DoctorType => {
    switch (doctorType) {
      case "NORMAL":
        return DoctorType.NORMAL;
      case "AFOTER_ATTENDING_PHYSICIAN":
        return DoctorType.AFOTER_ATTENDING_PHYSICIAN;
    }
  };

  private mapToDoctorRank = (doctorRank: Doctor["rank"]): DoctorRank => {
    switch (doctorRank) {
      case "CHIEF":
        return DoctorRank.CHIEF;
      case "NORMAL":
        return DoctorRank.NORMAL;
    }
  };
}
